import './index.scss';
import * as BABYLON from "babylonjs";
import * as GUI from "babylonjs-gui";
import { CameraInputTypes, TransformNode } from 'babylonjs';
import "babylonjs-loaders";
import "react-bootstrap";
import moment from 'moment';
import MobileDetect from 'mobile-detect';
// import handjs from 'handjs';

import "oimo";
import { getMats } from './mats';
import { createFlipper, Flipper  } from './flipper';
export const CCD = true;
export const FLIPPER_FRICTION = 0.9;

let innerGame:any = null;

$(document).ready(() => {
	window.addEventListener('initGame', (ev) => {
		if(!innerGame) {
			innerGame = new Game(ev);
		} 
	});
	window.addEventListener('endGame', (ev) => {
		if(innerGame) {
			innerGame.destroyGame();
			innerGame = null;
		} 
	});
	window.addEventListener('getGameScore', (event:any) => {
		if(innerGame) {
			event.detail.callback(innerGame.score);
		} else {
			event.detail.callback(0);
		}
	});		
});

class Game {	
	scene:BABYLON.Scene;
	SHOW_PHYSICS_MODELS = false;
	SHOW_OBSTACLES_MODELS = false;
	PHYSICS_MODELS_VISIBILITY = this.SHOW_PHYSICS_MODELS ? 0.5 : 0;
	OBSTACLES_MODELS_VISIBILITY = this.SHOW_PHYSICS_MODELS || this.SHOW_OBSTACLES_MODELS ? 0.5 : 0;
	GRAPHIC_MODELS_VISIBILITY =  1;
	CONSOLE_DEBUGGING = false;
	SHOW_POPUPS = true;
	SHOW_FPS = false;
	
	// increasing this will make the simulation better, but require more CPU
	SUBSTEPS_INIT = 2;
	SUBSTEPS_MAX = 4;
	TESTBED = false;
	DEBUG_KEYS = true;
	CAM_POS: number = this.TESTBED ? 2 : 0; // 0 = normal, 1 = table overview, 2 = testbed, 3 = zoom out, 4 = flippers, 5 = mobile 1, 6= mobile 2, 7 = free
	FLIPPER_AUTOFIRE = false;
	RAIN_O_BALLS = false;
	SHOW_AXES = this.CAM_POS > 0;
	SHADOWS = true;
	
	FLOOR_FRICTION = 0.9;
	WALL_FRICTION = 0.9;
	BALL_FRICTION = 0.84;
	BALL_RESTITUTION = 0.01;
	BOUNDS_RESTITUTION = 1;
	OBSTACLE_RESTITUTION = 140;
	OBSTACLE_FRICTION= 0.9;
	GRAVITY = 9.8 * 60;
	BALL_START_POSITION = new BABYLON.Vector3(-31.5, 1.35, 45);
	WORLD_ORIGIN = new BABYLON.Vector3(0, 0, 0);
	FIELD_CENTER = new BABYLON.Vector3(0, 10, 4);
	md = new MobileDetect(window.navigator.userAgent);
	goalies = ['EK21-Pinball-goalkeeper-belgie.png','EK21-Pinball-goalkeeper-duitsland.png', 'EK21-Pinball-goalkeeper-frankrijk.png', 'EK21-Pinball-goalkeeper-italie.png', 'EK21-Pinball-goalkeeper-turkije.png']
	
	PORTRAIT_CAMERA_TARGET = new BABYLON.Vector3(0, -8, -13);
	TALL_PORTRAIT_CAMERA_TARGET = new BABYLON.Vector3(0, -5, -10);
	
	cameraModifierZ = 107; //85;
	cameraModifierY = 48; // 65;
	
	MAX_BALLS = 8;
	START_BALLS = 3;
	
	BOTTOM_POS_Z = 56;
	SKIP_INTRO_ANIM = false;
	CLEAR_COLOR = new BABYLON.Color4(17/255,21/255,58/255, 1);
	introAnimationDone = false;
	
	startLoad:number = null;
	
	score:number = 0;
	highscoreToBeat:number = null;
	
	casePhysics:BABYLON.AbstractMesh;
	walls:BABYLON.AbstractMesh;
	backwall:BABYLON.AbstractMesh;
	tabletop:BABYLON.AbstractMesh;
	innerwallleft:BABYLON.AbstractMesh;
	
	goal:BABYLON.TransformNode;
	goalmeshes:any = null;
	
	arrowIsDown = false;
	canvas:HTMLCanvasElement = document.getElementById("renderCanvas") as HTMLCanvasElement;
	CANVAS_SCALE_STEPS = [1, 0.9, 0.8, 0.7];
	
	canvasScale: number = 0;
	scoreElement = document.getElementById("gamescore");
	gameContainer = document.getElementById("game-main");
	overlay = document.getElementById("game-overlay");
	loadingScreen = document.getElementById("loading-wrap");
	loadingContent = document.getElementById("loading-content");
	soundToggle = document.getElementById('soundToggle');
	infiniteToggle = document.getElementById('infiniteToggle');
	cameraToggle = document.getElementById('cameraToggle');
	cameraInputs = document.getElementById('cameraInputs');
	cameraPositionX = document.getElementById('cameraPositionX');
	cameraPositionY = document.getElementById('cameraPositionY');
	cameraPositionZ = document.getElementById('cameraPositionZ');
	targetPositionX = document.getElementById('targetPositionX');
	targetPositionY = document.getElementById('targetPositionY');
	targetPositionZ = document.getElementById('targetPositionZ');
	cameraPositionXi = document.getElementById('cameraPositionXi');
	cameraPositionYi = document.getElementById('cameraPositionYi');
	cameraPositionZi = document.getElementById('cameraPositionZi');
	targetPositionXi = document.getElementById('targetPositionXi');
	targetPositionYi = document.getElementById('targetPositionYi')
	targetPositionZi = document.getElementById('targetPositionZi')
	resetCameraButton = document.getElementById('resetCamera');
	
	bounds = this.gameContainer.getBoundingClientRect()
	
	engine = new BABYLON.Engine(this.canvas, true, {
		premultipliedAlpha: false,
		depth: true,
		preserveDrawingBuffer: !this.isMobileDevice(),
		stencil: true
	}, false);
	
	lastScore = 0;
	scoreTimeout = 3000;
	playInfinite = true; // temp on for testing
	gamePaused = true;
	
	highlightColor:BABYLON.Color3 = new BABYLON.Color3(0.968627, 0.650980, 0.000000);
	chevronLightColor:BABYLON.Color3 = new BABYLON.Color3(1, 1, 1);
	chevronLightOffColor:BABYLON.Color3 = new BABYLON.Color3(0, 0, 0);
	
	machineAngle = Math.PI*0.03;
	yAxis = new BABYLON.Vector3(0,1,0);
	xAxis = new BABYLON.Vector3(1,0,0);
	pinBallDiameter = 2.7;
	plungerHeight = 2.9;
	plungerMax = { x: -30.9, y: 1.5, z: 56 };
	plungerMin = { x: -30.9, y: 1.5, z: 61 };
	plungerMoveDownStep = 30;
	plungerMovedDown = false;
	plungerIsFiring = false;
	plungerFiredBall = false;
	plungerPinballDistance = 9;
	plungerIsForce = 100;
	plungerFireForceMultiplier = 260;
	plungerFireSteps = 0;
	plungerFireStepSize = 0.5;
	zeroVelocity = new BABYLON.Vector3(0,0,0);
	cubeUVFrontFaceOnly = [new BABYLON.Vector4(0, 0, 0, 0), new BABYLON.Vector4(0, 0, 1, 1), new BABYLON.Vector4(0, 0, 0, 0), new BABYLON.Vector4(0, 0, 0, 0), new BABYLON.Vector4(0, 0, 0, 0), new BABYLON.Vector4(0, 0, 0, 0)]
	cubeUVFrontCorner = [new BABYLON.Vector4(0, 0, 0, 0),  new BABYLON.Vector4(1/2, 0, 1, 1), new BABYLON.Vector4(0, 0, 0, 0),new BABYLON.Vector4(0/2, 0, 0.5, 1), new BABYLON.Vector4(0, 0, 0, 0), new BABYLON.Vector4(0, 0, 0, 0)]
	shadows: BABYLON.ShadowGenerator | null = null;
	shadowMeshes:any[] = [];
	meshesToUnIndex:any[] = [];
	meshesToFreezeWorldMatrix:any[] = [];
	currentSubStep = this.SUBSTEPS_INIT;
	leftFlipper:Flipper;
	rightFlipper:Flipper;
	leftIsPowered:boolean = false;
	rightIsPowered:boolean = false;
	
	goalieObstacle:BABYLON.Mesh;
	
	eifel_tower:BABYLON.AbstractMesh;
	eifel_tower_position = new BABYLON.Vector3(-17, 0, -18);
	big_ben:BABYLON.AbstractMesh;
	big_ben_position =  new BABYLON.Vector3(-17, 0, 28);
	pisa_tower:BABYLON.AbstractMesh;
	pisa_tower_position = new BABYLON.Vector3(17, 0, -18);
	mill:BABYLON.AbstractMesh;
	mill_position = new BABYLON.Vector3(17, 0, 28);
	
	goalObstacle:BABYLON.Mesh;
	goaliePlane:BABYLON.Mesh;
	goalieTexture:BABYLON.Texture;
	goalieMaterial:BABYLON.StandardMaterial;
	ballsInReserve = this.START_BALLS;
	defaultBackboardImage:any = null;
	goalNewBallBackboardImage:any = null;
	goalMaxBallBackboardImage:any = null;
	currentBackboardImage:any = null;
	soundOnImage:any = null;
	soundOffImage:any = null;
	currentSmallButtonImage:any = null;
	backboardTexture:BABYLON.DynamicTexture;
	backboardMaterial:BABYLON.StandardMaterial;
	backboardContext:any;
	// buttonShineTexture:BABYLON.Texture;
	leftButtonSbTexture:BABYLON.Texture;
	leftButtonSbMaterial:BABYLON.StandardMaterial;
	leftButtonShineMaterial:BABYLON.StandardMaterial;
	
	goalieObstaclePlanePosition:BABYLON.Vector3 = new BABYLON.Vector3(0.25,1.5,0);
	mobileGoalieObstaclePlanePosition:BABYLON.Vector3 = new BABYLON.Vector3(0.25,10,2.5);
	
	prevRenderWidth = 0;
	prevRenderHeight = 0;	
	
	releasePlungerSound:BABYLON.Sound
	goalSound:BABYLON.Sound
	gameStart:BABYLON.Sound
	flipperSound:BABYLON.Sound
	chipsSound:BABYLON.Sound
	rouletteSound:BABYLON.Sound
	slotsSound:BABYLON.Sound
	cardsSound:BABYLON.Sound
	loseSound:BABYLON.Sound
	backgroundMusic:BABYLON.Sound
	// backgroundMusic2:BABYLON.Sound
	minfps = 60;
	minshadowfps = 50;
	
	maxShadowResolution = 1024;
	minShadowResolution = 256;
	currentShadowResolution = this.maxShadowResolution;
	maxBlur = 6;
	currentBlur = 2;
	minBlur = 2;
	shadowResolutionOptimisation = new BABYLON.CustomOptimization(1);
	
	// resolution will scale when going under the MIN or over the GOOD for a while
	GOOD_FPS = 40;
	MIN_FPS = 25;
	
	optimizerOptions = new BABYLON.SceneOptimizerOptions(this.minfps, 1000);
	optimizer:BABYLON.SceneOptimizer	
	gravityVector = new BABYLON.Vector3(0,-this.GRAVITY, 0);
	physicsEngine:any = new BABYLON.AmmoJSPlugin();
	
	pinballMachine:BABYLON.TransformNode
	environmentLight:BABYLON.HemisphericLight
	caseLight1:BABYLON.PointLight
	caseLight2:BABYLON.PointLight
	
	floor:BABYLON.Mesh;
	ceiling:BABYLON.Mesh;
	frontwall:BABYLON.Mesh;
	plunger:BABYLON.AbstractMesh;
	leftButton:BABYLON.TransformNode;
	leftButtonMeshes:Array<BABYLON.AbstractMesh>;
	smallButton:BABYLON.TransformNode;
	smallButtonMeshes:Array<BABYLON.AbstractMesh>;
	rightButton:BABYLON.TransformNode;
	rightButtonMeshes:Array<BABYLON.AbstractMesh>;
	grassTexture:BABYLON.Texture;
	grassMaterial:BABYLON.StandardMaterial;
	fieldLinesTexture:BABYLON.Texture;
	fieldLinesMaterial:BABYLON.StandardMaterial;
	pinBall:BABYLON.Mesh;
	ballTexture:BABYLON.Texture;
	ballMaterial:BABYLON.StandardMaterial;
	skybox:BABYLON.Mesh;
	skyboxMaterial:BABYLON.StandardMaterial;
	skyboxTexture:BABYLON.CubeTexture;
	
	chevronLight:BABYLON.AbstractMesh;
	chevronLights:BABYLON.AbstractMesh[] = [];
	chevronTriggers:BABYLON.Vector3[] = [];
	amountOfLights = 6;
	
	camera:BABYLON.FlyCamera
	currentCameraPreset = this.isPhoneDevice() ? 9 : 0;
	highlightLayer:BABYLON.HighlightLayer
	lastSubloopRun = 0;
	lastSubloopRender = 0;
	
	ballUi = $('#balls-left-ui');
	ballCount = $('#ball-count');
	
	ledboardTexture:BABYLON.Texture = null;
	ledboardMaterial:BABYLON.StandardMaterial = null;
	goalNetTexture:BABYLON.Texture = null;
	goalNetMaterial:BABYLON.StandardMaterial = null;
	fpsLabel = document.getElementById("fpsLabel");
	
	//
	setposCount = 40;
	goalieX = 0;
	goalieMax = 6;
	goalieMin = -6;
	goalieGoingRight = true;
	renderTimePassed:number = 0;
	
	collisionObjects:Array<any> = null;
	
	goalieMovement = 20;		
	autoFireId:any = null;
	autoFirePause = 100;

	scoreResetEvent = new CustomEvent('scoreReset');
	lastRenderRun = 0;
		lastActualRender = 0;
		soccerBall:BABYLON.TransformNode
		i = 0;
		
		backboardOpacity = 0;
		backboardAnimating = false;
		
		leftUpId:any = [];
		rightUpId:any = [];
		draggingId:any = null;
		draggingPlunger = false;
		dragDistance = 0;
		dragStart = 0;		
		
		quicklaunch = false;
		quicklaunchTime = 0;		
		nextShine = 0;	
		
		cameraControlsActive = false;	
		curSubSteps: number;
		win:any = this.CONSOLE_DEBUGGING ? window : null;
		onRenderList: any[] = [];
		sounds:BABYLON.Sound[] = [];
	
	constructor(event:any) {		
		BABYLON.Logger.LogLevels = BABYLON.Logger.NoneLogLevel		
		
		if(this.CONSOLE_DEBUGGING) {
			this.win.cameraModifierZ = this.cameraModifierZ;
			this.win.cameraModifierY = this.cameraModifierY;
			this.win.setPresetCameraPos = (preset:number) => this.setPresetCameraPos(preset);
			
			this.win.PORTRAIT_CAMERA_TARGET = this.PORTRAIT_CAMERA_TARGET;
			this.win.TALL_PORTRAIT_CAMERA_TARGET = this.TALL_PORTRAIT_CAMERA_TARGET;
		}
		
		
		this.canvas.width = window.innerWidth; // TODO: check for mobile!
		this.canvas.height = window.innerHeight; // TODO: check for mobile!
		
		
		// Create the scene space
		this.scene = new BABYLON.Scene(this.engine, {
			useMaterialMeshMap: true,
			useGeometryUniqueIdsMap: true
		});
		this.scene.clearColor = this.CLEAR_COLOR;
		this.scene.autoClear = false;
		this.scene.collisionsEnabled = false;
		
		
		// this.scene.debugLayer.show({
		//     overlay:false,
		//     globalRoot:document.getElementById('debug-overlay')
		// });
		this.scene.blockMaterialDirtyMechanism = true;
		this.scene.audioEnabled = false;
		
		this.releasePlungerSound = new BABYLON.Sound('releasePlungerSound', './assets/sounds/plunger.mp3', this.scene);
		this.goalSound = new BABYLON.Sound('goalSound', './assets/sounds/doelpunt.mp3', this.scene);
		this.gameStart = new BABYLON.Sound('gameStart', './assets/sounds/aanvallen.mp3', this.scene);
		this.flipperSound = new BABYLON.Sound('flipperSound', './assets/sounds/flippers.mp3', this.scene, null, {loop:false, autoplay:false});
		this.chipsSound = new BABYLON.Sound('chipsSound', './assets/sounds/point-score.mp3', this.scene);
		this.rouletteSound = new BABYLON.Sound('rouletteSound', './assets/sounds/point-score.mp3', this.scene);
		this.slotsSound = new BABYLON.Sound('slotsSound', './assets/sounds/point-score.mp3', this.scene);
		this.cardsSound = new BABYLON.Sound('cardsSound', './assets/sounds/point-score.mp3', this.scene);
		this.loseSound = new BABYLON.Sound('loseSound', './assets/sounds/fail-ball.mp3', this.scene);
		this.backgroundMusic = new BABYLON.Sound('backgroundMusic', './assets/sounds/publiek-stadion.mp3', this.scene, null, {loop:true, autoplay:false});

		this.sounds = [
			this.releasePlungerSound,
			this.goalSound,
			this.gameStart,
			this.flipperSound,
			this.chipsSound,
			this.rouletteSound,
			this.slotsSound,
			this.cardsSound,
			this.loseSound,
			this.backgroundMusic,
		]

		
		this.shadowResolutionOptimisation.onApply = (scene, optimizer) => this.optimizeShadowResolution(scene, optimizer);
		
		this.optimizerOptions.addOptimization(this.shadowResolutionOptimisation);
		this.optimizerOptions.addOptimization(new BABYLON.TextureOptimization(0, 512));
		this.optimizerOptions.addOptimization(new BABYLON.PostProcessesOptimization(0));
		
		this.optimizer = new BABYLON.SceneOptimizer(this.scene, this.optimizerOptions);
		this.scene.enablePhysics(this.gravityVector, this.physicsEngine);
		(this.scene as any).workerCollisions = true; // somehow typescript doesn't recognize this
		this.physicsEngine.setTimeStep(1/120);
		//
		// // this function can dynamically change the substeps,
		// // but that will cause the ball to behave rather differently,
		// // so unfortunately we can't actually use it
		this.setSubSteps(this.SUBSTEPS_INIT);		
		
		this.pinballMachine = new BABYLON.TransformNode("pinballMachine", this.scene);
	
		// temp disabled, is for the outsides of the case
		// var roomLight1 = new BABYLON.PointLight("roomLight1", new BABYLON.Vector3(120, 100, 120), this.scene);
		// roomLight1.intensity = 1;
		// var roomLight2 = new BABYLON.PointLight("roomLight2", new BABYLON.Vector3(-120, 100, -120), this.scene);
		// roomLight2.intensity = 1;
		
		if(this.SHOW_PHYSICS_MODELS) {
			var caseLight1Obj = BABYLON.MeshBuilder.CreateSphere("caseLight1Obj", {segments:8}, this.scene);
			var caseLight2Obj = BABYLON.MeshBuilder.CreateSphere("caseLight1Obj", {segments:8}, this.scene);
			var lightlessmaterial = new BABYLON.StandardMaterial('lightlessmaterial', this.scene);
			lightlessmaterial.emissiveColor = new BABYLON.Color3(1,1,1);
			lightlessmaterial.specularColor = new BABYLON.Color3(1,1,1);
			lightlessmaterial.diffuseColor = new BABYLON.Color3(1,1,1);
			// lightlessmaterial.disableLighting = true;
			caseLight1Obj.material = lightlessmaterial;
			caseLight2Obj.material = lightlessmaterial;
			caseLight1Obj.position = this.caseLight1.position;
			caseLight2Obj.position = this.caseLight2.position;
			caseLight1Obj.visibility = 0.3;
			caseLight2Obj.visibility = 0.3;
		}
		
	
		this.camera = new BABYLON.FlyCamera("Camera", new BABYLON.Vector3(40,60,78+20), this.scene);
		
		if(this.CONSOLE_DEBUGGING) this.win.camera = this.camera;
		
		this.scene.setActiveCameraByName("Camera");
		
		// Add the highlight layer.
		this.highlightLayer = new BABYLON.HighlightLayer("highlightLayer", this.scene);
		
		
		
		this.soundToggle.addEventListener('click', (ev) => this.onToggleSound(ev));
		// infiniteToggle.addEventListener('click', onToggleInfinite);
		// cameraToggle.addEventListener('click', toggleCameraControls);
		// cameraPositionY.addEventListener('change', (e:any) => { if(this.camera) this.camera.position.y = parseInt(e.target.value); });
		// cameraPositionX.addEventListener('change', (e:any) => { if(this.camera) this.camera.position.x = parseInt(e.target.value); });
		// cameraPositionZ.addEventListener('change', (e:any) => { if(this.camera) this.camera.position.z = parseInt(e.target.value); });
		// targetPositionX.addEventListener('change', (e:any) => { if (this.camera && this.camera.lockedTarget) this.camera.lockedTarget.x = parseInt(e.target.value); });
		// targetPositionY.addEventListener('change', (e:any) => { if (this.camera && this.camera.lockedTarget) this.camera.lockedTarget.y = parseInt(e.target.value); });
		// targetPositionZ.addEventListener('change', (e:any) => { if (this.camera && this.camera.lockedTarget) this.camera.lockedTarget.z = parseInt(e.target.value); });
		// resetCameraButton.addEventListener('click', resetCamera);
		
		window.addEventListener('spinsLeftChanged', (event:any) => {
			this.ballsInReserve = event.detail.spinsLeft;
		});
		window.addEventListener('updateHighScores', (event:any) => {
			this.updateHighScore(event.detail.highscores);
		});

		
		this.scene.onAfterPhysicsObservable.add(() => {
			this.runRenderList();
		});
		this.scene.onBeforePhysicsObservable.add(() => {
			this.checkObstacleCollisions();
		});	
		
		this.soccerBall = new BABYLON.TransformNode("soccerBall", this.scene);		
		
		
		// window.addEventListener('startGame', startGame);
		this.startLoad = moment().valueOf();
		this.updateSceneSize();
		
		this.buildScene(() => {	
			if(this.collisionObjects == null) {
				this.collisionObjects = [
					{ name:	'big_ben', lastTrigger:0, cooldown: 500, isGoal:false, points: 10, object: this.big_ben, func: (object:any) => { this.shakeAnimation(this.big_ben); this.highLightMesh(this.big_ben); }, sound: this.cardsSound },
					{ name:	'mill', lastTrigger:0, cooldown: 500, isGoal:false, points: 10, object: this.mill, func: (object:any)  => { this.shakeAnimation(this.mill); this.highLightMesh(this.mill); for(let mesh of this.mill.getChildMeshes()) {this.highLightMesh(mesh);} }, sound: this.rouletteSound },
					{ name:	'pisa_tower', lastTrigger:0, cooldown: 500, isGoal:false, points: 20, object: this.pisa_tower, func: (object:any)  => { this.shakeAnimation(this.pisa_tower); this.highLightMesh(this.pisa_tower); }, sound: this.rouletteSound },
					{ name:	'eifel_tower', lastTrigger:0, cooldown: 500, isGoal:false, points: 20, object: this.eifel_tower, func: (object:any)  => { this.shakeAnimation(this.eifel_tower); this.highLightMesh(this.eifel_tower); }, sound: this.chipsSound },
					{ name: 'goal', lastTrigger:0, cooldown: 3000, isGoal:true, points: 1000, object: this.goalObstacle, func: (object:any)  => { this.handleGoal(object); this.highLightMesh(this.goalmeshes[0]); this.highLightMesh(this.goalmeshes[1]); this.highLightMesh(this.backwall); }, sound:this.goalSound }									
				];
			}

			// Add lights to the scene
			this.environmentLight = new BABYLON.HemisphericLight("environmentLight", this.WORLD_ORIGIN.clone(), this.scene);
			this.environmentLight.intensity = 1;
			this.caseLight1 = new BABYLON.PointLight("caseLight1", new BABYLON.Vector3(0, 70, 80), this.scene);
			this.caseLight1.intensity = 0.8;
			this.caseLight2 = new BABYLON.PointLight("caseLight2", new BABYLON.Vector3(0, 70, -40), this.scene);
			this.caseLight2.intensity = 0.8;
			this.caseLight2.excludedMeshes = this.chevronLights;
			
				
			if (this.SHADOWS) {
				this.shadows = new BABYLON.ShadowGenerator(1024, this.caseLight2);
			}
			
			var startInterval:any = null;
			startInterval = setInterval(() => {
				if(!document.hidden) {
					this.createEventListeners();
					clearInterval(startInterval);						
					this.startGame()
				}
			}, 500);
		});		
		// console.log('game constructor end')	
	}
	
	// crude optimisation
	startOptimisingResolution() {
		let rescaleIndicatorCount = 0;
		setInterval(() => {
			let fps = this.engine.getFps();
			// ////console.log(`FPS ${Math.round(fps)} SCALE ${this.canvasScale} = ${CANVAS_SCALE_STEPS[this.canvasScale]}`);
			
			// check whether FPS indicates to scale up or down
			let newScale: boolean | null = null;
			let scaleSteps = this.CANVAS_SCALE_STEPS;
			if (fps > this.GOOD_FPS && this.canvasScale > 0) newScale = false;
			else if (fps < this.MIN_FPS && this.canvasScale < scaleSteps.length-1) newScale = true;
			
			// only rescale if the FPS crosses the treshold multiple times in a row
			if (newScale != null) {
				// ////console.log(`FPS ${Math.floor(fps)}!`);
				rescaleIndicatorCount++;
				if (rescaleIndicatorCount >= 4) {
					this.rescale(newScale);
					rescaleIndicatorCount = 0;
				}
			} else {
				if (rescaleIndicatorCount != 0) {
					// ////console.log("Meh");
					rescaleIndicatorCount = 0;
				}
			}
			
		}, 500);
	}
	
	setShadowMapResolution(resolution:number) {
		if(this.SHADOWS) {
			if(this.shadows) {
				for(let mesh of this.shadowMeshes) {
					this.shadows.removeShadowCaster(mesh);
				}
				this.shadows.dispose();
				this.shadows = null;
			}
			this.shadows = new BABYLON.ShadowGenerator(resolution, this.caseLight2);
			for(let mesh of this.shadowMeshes) {
				this.shadows.addShadowCaster(mesh);
			}
			this.currentShadowResolution = resolution;
		}
	}
	
	setSubSteps(s: number) {
		if (this.curSubSteps != s) {
			this.curSubSteps = s;
			this.physicsEngine.setFixedTimeStep(1/(60 * this.curSubSteps));
		}
	}
	
	setPresetCameraPos(pos:number) {
		let settings = this.getPresetCameraSettings(pos);
		this.camera.position = settings.position;
		this.camera.lockedTarget = settings.target;
		// this.camera.rotation = this.WORLD_ORIGIN.clone();
		this.camera.fov = settings.fov;
		
		// (cameraPositionX as any).value = settings.position.x;
		// (cameraPositionY as any).value = settings.position.y;
		// (cameraPositionZ as any).value = settings.position.z;
		// cameraPositionXi.innerHTML = "" + settings.position.x;
		// cameraPositionYi.innerHTML = "" + settings.position.y;
		// cameraPositionZi.innerHTML = "" + settings.position.z;
		// if(settings.target) {
		// 	(targetPositionX as any).value = settings.target.x;
		// 	(targetPositionY as any).value = settings.target.y;
		// 	(targetPositionZ as any).value = settings.target.z;
		// 	targetPositionXi.innerHTML = "" + settings.target.x;
		// 	targetPositionYi.innerHTML = "" + settings.target.y;
		// 	targetPositionZi.innerHTML = "" + settings.target.z;
		// }
		
		if(pos == 7) {
			this.camera.attachControl(this.canvas);
		} else {
			this.camera.detachControl(this.canvas);
		}
		
		if(pos < 8) this.currentCameraPreset = pos;
	}
	
	getPresetCameraSettings(pos:number) {
		var position = this.camera.position;
		var target = this.camera.lockedTarget;
		var fov = 0.8;
		if (pos == 0) {
			position = new BABYLON.Vector3(0,28,95);
			target = this.WORLD_ORIGIN.clone();
		} else if (pos == 1) {
			position = new BABYLON.Vector3(40, 60, 78+20);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 2) {
			position = new BABYLON.Vector3(20, 60, 58);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 3) {
			let z = 2;
			position = new BABYLON.Vector3(40*z, 60*z, (78+20)*z);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 4) {
			position = new BABYLON.Vector3(0,0,78);
			target = new BABYLON.Vector3(0, -20, 0)
		} else if (pos == 5) {
			position = new BABYLON.Vector3(0,150,77);
			target = new BABYLON.Vector3(0,15,0);
		} else if (pos == 6) {
			position = new BABYLON.Vector3(0,114,109);
			target = new BABYLON.Vector3(0,15,0);
		} else if (pos == 7) {
			position = new BABYLON.Vector3(0,0,0);
			target = null;
		} else if (pos == 8) {
			var ratio = this.canvas.width / this.canvas.height;
			if(ratio < 1) {
				target = this.PORTRAIT_CAMERA_TARGET.clone();
				position = new BABYLON.Vector3(0, 122.5, 92.5);
			} else {
				position = new BABYLON.Vector3(0,28,95);
				target = this.WORLD_ORIGIN.clone();
			}
		} else if (pos == 9) {
			var ratio = this.canvas.width / this.canvas.height;
			target = new BABYLON.Vector3(0, 10, 0);
			if(ratio >= 0.75 && ratio < 1) {
				target = new BABYLON.Vector3(0, 10, 0);
				// position = new BABYLON.Vector3(0, 112.5, 57);
				position = new BABYLON.Vector3(0, 75, 115);
			} else if(ratio >= 0.65 && ratio < 0.75) {
				target = new BABYLON.Vector3(0, 10, 0);
				// position = new BABYLON.Vector3(0, 112.5, 57);
				position = new BABYLON.Vector3(0, 95, 115);
			} else if(ratio >= 0.55 && ratio < 0.65) {
				target = new BABYLON.Vector3(0, 10, -5);
				// position = new BABYLON.Vector3(0, 112.5, 57);
				position = new BABYLON.Vector3(0, 120, 105);
				
			} else if(ratio >= 0.5 && ratio < 0.55) {
				target = new BABYLON.Vector3(0, 10, -5);
				// position = new BABYLON.Vector3(0, 112.5, 57);
				position = new BABYLON.Vector3(0, 135, 97.5);
			} 
			else if(ratio < 0.5) {
				target = new BABYLON.Vector3(0, 10, -5);
				// position = new BABYLON.Vector3(0, 112.5, 57);
				position = new BABYLON.Vector3(0, 155, 75);
			} else {
				position = new BABYLON.Vector3(0,28,95);
				target = this.WORLD_ORIGIN.clone();
			}
		}
		return {
			position: position,
			target: target,
			fov: fov
		}
	}
	
	spawnBall(worldPos: BABYLON.Vector3) {
		let pinBall = BABYLON.MeshBuilder.CreateSphere("pinBall", {diameter: this.pinBallDiameter, segments:3}, this.scene);
		pinBall.physicsImpostor = new BABYLON.PhysicsImpostor(pinBall, BABYLON.PhysicsImpostor.SphereImpostor, {mass:1, restitution: this.BALL_RESTITUTION, friction: this.BALL_FRICTION}, this.scene);
		pinBall.material = this.ballMaterial;
		pinBall.position = worldPos;
		pinBall.isPickable = false;
		
		this.soccerBall.setParent(pinBall);
		this.soccerBall.position = new BABYLON.Vector3(0,0,0);
		this.soccerBall.setEnabled(true);
		this.shadowMeshes.push(pinBall);
		
		if (CCD) {
			let bod = pinBall.physicsImpostor.physicsBody;
			bod.setCcdMotionThreshold(this.pinBallDiameter / 2);
			bod.setCcdSweptSphereRadius(this.pinBallDiameter / 2);
		}
		return pinBall;
	}
	
	spawnTestBall() {
		this.spawnBall(new BABYLON.Vector3(-10 + Math.random() * 20, 20, 0));
	}
	
	createObstacle(scene:BABYLON.Scene, position:BABYLON.Vector3, w:number, h:number, d:number) {
		var obstacle = BABYLON.MeshBuilder.CreateBox("obstacle", {width:w, height:h, depth:d}, scene);
		obstacle.position = position;
		obstacle.physicsImpostor = new BABYLON.PhysicsImpostor(obstacle, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction:0}, scene);
		obstacle.setParent(this.pinballMachine);
		obstacle.visibility = this.OBSTACLES_MODELS_VISIBILITY;
		return obstacle;
	}
	
	onRender(delta:number) {
		if (this.pinBall && this.pinBall.physicsImpostor) {
			if (this.ballHittedBottom()) {
				this.onOpponentsGoal();
			}
		}
		if (!this.plungerFiredBall && !this.plungerIsFiring){ // && pinBall && pinBall.position.x < -30) {
			this.correctBallposition();
		}

		if (this.plunger) {
			if (this.arrowIsDown || this.quicklaunch) {
				this.pullDownPlunger(this.quicklaunch ? delta * 2 : delta);
				this.quicklaunch = this.plunger.position.z < this.plungerMin.z;// moment().valueOf() < this.quicklaunchTime;
			} else if(this.draggingPlunger) {
				this.pullDownPlunger(delta);
			} else {
				this.releasePlunger(delta);
			}
		}
		this.moveGoalieObstacle(delta);		
	}
	
	onOpponentsGoal() {
		this.loseSound.play();
		this.startAutoFirePinBall();
	}
	
	addBall() {
		if(!this.pinBall) {
			this.pinBall = this.spawnBall(this.BALL_START_POSITION.clone());
			this.pinBall.setParent(this.pinballMachine);
			// setCollisionHandlers();
		}
	}
	
	removeBall() {
		if(this.pinBall) {
			this.pinBall.physicsImpostor.dispose();
			this.pinBall.physicsImpostor = null;
			this.pinBall.dispose();
			this.pinBall = null;
		}
	}
	
	resetBall() {
		if(this.plunger != null) {
			this.plunger.position = new BABYLON.Vector3(this.plungerMax.x, this.plungerMax.y, this.plungerMax.z); // make sure the plunger is in the starting position
			this.plungerIsFiring = false;
			this.plungerMovedDown = false;
			this.plungerFiredBall = false;
		}
		if(this.pinBall && this.pinBall.physicsImpostor) {
			--this.ballsInReserve;
			this.updateBallUi();
			if(this.ballsInReserve <= 0) {
				this.ballsInReserve = this.START_BALLS;
				this.showSubmitscore();
			}
			
			// engine.clear(this.CLEAR_COLOR, true, true);
			this.pinBall.visibility = this.PHYSICS_MODELS_VISIBILITY;
			this.pinBall.physicsImpostor.setAngularVelocity(this.zeroVelocity);
			this.pinBall.physicsImpostor.setLinearVelocity(this.zeroVelocity);
			this.pinBall.position = this.BALL_START_POSITION.clone();
			this.pinBall.visibility = this.GRAPHIC_MODELS_VISIBILITY;
		}
	}
	
	checkLightIntersection(delta:number) {
		if(this.chevronLights && this.pinBall) {
			let lightsAmount = this.chevronLights.length;
			for(let i = 0; i < lightsAmount; i++) {
				let light:any = this.chevronLights[i];
				let trigger = this.chevronTriggers[i];
				if(this.pinBall.intersectsPoint(this.localToGlobal(trigger))) {
					if(!this.highlightLayer.hasMesh(light)) {
						light.material.emissiveColor = this.chevronLightColor;
						this.highlightLayer.addMesh(light, this.chevronLightColor);
					}
				} else {
					if(this.highlightLayer.hasMesh(light))  {
						setTimeout(() => { light.material.emissiveColor = this.chevronLightOffColor; this.highlightLayer.removeMesh(light); this.highlightLayer._disposeMesh(light);light = null;	trigger = null; }, 150);
						
					}
				}
				
			}
		}
	}
	
	highLightMesh(mesh:any, duration:number = 300, color:BABYLON.Color3 = this.highlightColor) {
		if (mesh) {
			var thisMesh:BABYLON.Mesh = (mesh as BABYLON.Mesh);
			if(!this.highlightLayer.hasMesh(thisMesh)) {
				this.highlightLayer.addMesh(thisMesh, color);
				setTimeout(() => { this.highlightLayer.removeMesh(thisMesh); this.highlightLayer._disposeMesh(thisMesh); if(!this.gamePaused) this.engine.clear(this.CLEAR_COLOR, true, true); }, duration);
			}
			
		}
	}
	//
	highlightObstacle(mesh:any, defaultMat:BABYLON.StandardMaterial, highlightMat:BABYLON.StandardMaterial) {
		if(mesh && defaultMat && highlightMat) {
			mesh.material = highlightMat;
			var timeout:any = null;
			timeout = setTimeout(() => { mesh.material = defaultMat; clearTimeout(timeout); }, 300);
		}
	}
	
	pullDownPlunger(delta:number) {
		if (this.plunger) {
			if (this.plunger.position.z < this.plungerMin.z) {
				if(this.draggingPlunger) {
					let plungerPos = this.plungerMax.z - this.dragDistance;
					if(plungerPos < this.plungerMax.z) plungerPos = this.plungerMax.z;
					if(plungerPos > this.plungerMin.z) plungerPos = this.plungerMin.z;
					this.plunger.position.z = plungerPos;
					this.plungerMovedDown = true;
				} else {
					this.plunger.position.z += this.plungerMoveDownStep * delta;
					this.plungerMovedDown = true;
				}
			}
		}
	}
	
	releasePlunger(delta:number) {
		if (this.plunger && this.pinBall && this.pinBall.physicsImpostor) {
			if (this.plungerIsFiring) {
				var v = ++this.plungerFireSteps * this.plungerFireStepSize;
				var dx = v * v;
				var y = this.plunger.position.z - dx ;
				if (y < this.plungerMax.z) {
					y = this.plungerMax.z;
					this.plunger.position.z = y;
					this.plungerIsFiring = false;
					this.plungerFiredBall = false;
				} else {
					this.plunger.position.z = y;
					if(!this.releasePlungerSound.isPlaying) { this.releasePlungerSound.play(); }
				}
				if (!this.plungerIsFiring && this.pinballIsInVicinityOfPlunger()) {
					this.plungerIsForce = -(this.plungerFireForceMultiplier * Math.abs(this.plungerMax.z - this.plunger.position.z));
					if(!this.plungerFiredBall) this.pinBall.physicsImpostor.applyImpulse(this.localToGlobal(new BABYLON.Vector3(0, this.plungerIsForce, this.plungerIsForce)), this.pinBall.getAbsolutePosition());
					this.plungerFiredBall = true;
				}
			} else {
				if (this.plunger.position.z >= this.plungerMax.z) {
					this.plungerIsFiring = true;
					this.plungerFireSteps = 0;
					this.plungerIsForce = -(this.plungerFireForceMultiplier * Math.abs(this.plungerMax.z - this.plunger.position.z));
					if (this.pinballIsInVicinityOfPlunger()) {
						if(!this.plungerFiredBall) this.pinBall.physicsImpostor.applyImpulse(this.localToGlobal(new BABYLON.Vector3(0, this.plungerIsForce, this.plungerIsForce)), this.pinBall.getAbsolutePosition());
						this.plungerFiredBall = true;
					} else {
						this.plungerFiredBall = false;
					}
				}
			}
		}
	}
	
	pinballIsInVicinityOfPlunger() {
		if (this.pinBall && this.plunger && this.pinBall.position.x < -30) {
			return (Math.abs(this.pinBall.position.x - this.plunger.position.x) < 0.5 &&
			Math.abs(this.pinBall.position.y - this.plunger.position.y) < 0.5 &&
			Math.abs(this.pinBall.position.z - this.plunger.position.z) < this.plungerPinballDistance);
		}
		return false;
	}
	
	pinballIsUnderPlunger() {
		if (this.pinBall && this.plunger&& this.pinBall.position.x < -30) {
			return (Math.abs(this.pinBall.position.x - this.plunger.position.x) < 0.5 &&
			Math.abs(this.pinBall.position.y - this.plunger.position.y) < 0.5 &&
			this.plunger.position.z - this.pinBall.position.z < this.plungerPinballDistance - 1);
		}
		return false;
	}
	
	correctBallposition() {
		if (this.pinballIsUnderPlunger()) {
			this.pinBall.position.x = this.plunger.position.x;
			this.pinBall.position.y = this.pinBallDiameter / 2;
			this.pinBall.position.z = this.plunger.position.z - this.plungerPinballDistance;
			return true;
		}
		return false;
	}
	
	moveGoalieObstacle(delta:number) {
		if(this.goalieObstacle && delta > 0) {
			this.goalieX = this.goalieObstacle.position.x;
			if(this.goalieX <= this.goalieMin) {
				this.goalieGoingRight = true;
			} else if(this.goalieX >= this.goalieMax) {
				this.goalieGoingRight = false;
			} else {
				if(Math.random() < 0.15) {
					this.goalieChangeDirection();
				}
			}
			if(this.goalieGoingRight) {
				this.goalieX += this.goalieMovement * delta;
				if(this.goalieX > this.goalieMax) {
					this.goalieX = this.goalieMax;
				}
			} else {
				this.goalieX -= this.goalieMovement * delta;
				if(this.goalieX < this.goalieMin) {
					this.goalieX = this.goalieMin;
				}
			}
			this.goalieObstacle.position.x = this.goalieX;
		}
	}
	
	goalieChangeDirection() {
		this.goalieGoingRight = !this.goalieGoingRight;
		if(this.pinBall && Math.random() > 0.1) {
			if(this.pinBall.position.x > -30) {
				var ballX = this.pinBall.position.x;
				var ballZ = this.pinBall.position.z;
				if(ballZ > 10 || ballZ < -40) {
					if( 0 > ballX) {
						this.goalieGoingRight = true;
					} else if ( 0 < ballX) {
						this.goalieGoingRight = false;
					} else {
						this.goalieGoingRight = Math.random() > 0.5;
					}
				} else {
					if( 0 < ballX) {
						this.goalieGoingRight = true;
					} else if ( 0 > ballX) {
						this.goalieGoingRight = false;
					} else {
						this.goalieGoingRight = Math.random() > 0.5;
					}
				}
			}
		}
	}
	
	ballHittedBottom() {
		return (this.pinBall && this.pinBall.position.z > 56);
	}
	
	clearAutoFirePinBall() {
		if (this.autoFireId) {
			clearTimeout(this.autoFireId);
			this.autoFireId = null;
		}
	}
	
	startAutoFirePinBall() {
		this.clearAutoFirePinBall();
		this.resetBall();
	}
	
	localToGlobal(vect:BABYLON.Vector3) {
		return BABYLON.Vector3.TransformCoordinates(vect, this.pinballMachine.getWorldMatrix().clone());
	}
	
	globalToLocal(vect:BABYLON.Vector3) {
		return BABYLON.Vector3.TransformCoordinates(vect, this.pinballMachine.getWorldMatrix().clone().invert());
	}
	
	updateCamera() {
		this.setPresetCameraPos(this.isPhoneDevice() ? 9 : this.currentCameraPreset);
	}
	
	updateFps() {
		if(this.fpsLabel) this.fpsLabel.innerHTML = this.engine.getFps().toFixed() + " fps";
	}
	
	runRenderList() {
		for (let f of this.onRenderList) {
			f();
		}
	}
	//
	unIndexMeshes() {
		for(let mesh of this.meshesToUnIndex) {
			if(mesh.convertToUnIndexedMesh)	mesh.convertToUnIndexedMesh();
			if(mesh.cullingStrategy) mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
		}
	}
	
	freezeMeshWorldMeshes() {
		for(let mesh of this.meshesToFreezeWorldMatrix) {
			if(mesh.freezeWorldMatrix)	mesh.freezeWorldMatrix();
		}
	}
	
	scoreAnimation(score:number, position:BABYLON.Vector3, object:BABYLON.AbstractMesh) {
		let element = document.createElement('div');
		element.innerHTML = `${score}<span>${score}</span>`;
		element.classList.add('point-indicator');
		let container = document.getElementById("gamepoint-wrap");
		container.appendChild(element);
		
		var transformationMatrix = this.camera.getTransformationMatrix();
		var projectedPosition = BABYLON.Vector3.Project(object.position, BABYLON.Matrix.Identity(), transformationMatrix, this.camera.viewport.toGlobal(this.engine.getRenderWidth() * this.engine.getHardwareScalingLevel(), this.engine.getRenderHeight() * this.engine.getHardwareScalingLevel()));
		
		let newLeft:string = `${projectedPosition.x - $(element).width()}px`;
		let newTop:string = `${projectedPosition.y - $(element).height()}px`;
		element.style.top = newTop;
		element.style.left = newLeft;
		
		element.addEventListener("animationend", () => {
			container.removeChild(element);
		});
		element.addEventListener("webkitAnimationEnd", () => {
			container.removeChild(element);
		});
		element.addEventListener("oAnimationEnd", () => {
			container.removeChild(element);
		});
		element.addEventListener("MSAnimationEnd", () => {
			container.removeChild(element);
		});
	}
	
	shakeAnimation(item:BABYLON.AbstractMesh) {
		let shakeAnim = new BABYLON.Animation("shakeAnimation", "position.x", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
		let startX = item.position.x;
		let directionX = Math.random() < 0.5 ? 1 : -1;
		let directionZ = Math.random() < 0.5 ? 1 : -1;
		let shakeKeys = [
			{
				frame: 0,
				value: startX
			},
			{
				frame: 10,
				value: startX + 0.3 * directionX
			},
			{
				frame: 20,
				value: startX - 0.3 * directionX
			},
			{
				frame: 30,
				value: startX + 0.2 * directionX
			},
			{
				frame: 40,
				value: startX - 0.2 * directionX
			},
			{
				frame:50,
				value: startX + 0.1 * directionX
			},
			{
				frame: 60,
				value: startX
			}
		];
		shakeAnim.setKeys(shakeKeys);
		let shakeAnim2 = new BABYLON.Animation("shakeAnimation2", "position.z", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
		let startZ = item.position.z;
		let shakeKeysZ = [
			{
				frame: 0,
				value: startZ
			},
			{
				frame: 10,
				value: startZ + 0.3 * directionZ
			},
			{
				frame: 20,
				value: startZ - 0.3 * directionZ
			},
			{
				frame: 30,
				value: startZ + 0.2 * directionZ
			},
			{
				frame: 40,
				value: startZ - 0.2 * directionZ
			},
			{
				frame:50,
				value: startZ + 0.1 * directionZ
			},
			{
				frame: 60,
				value: startZ
			}
		];
		shakeAnim2.setKeys(shakeKeysZ);
		this.scene.beginDirectAnimation(item, [shakeAnim, shakeAnim2], 0, 60, false, 2, () => {
			item.position.x = startX;
			item.position.z = startZ;
			this.scene.removeAnimation(shakeAnim);
			this.scene.removeAnimation(shakeAnim2);
		});
	}

	toggleSound() {
		try {
			if(this.scene.audioEnabled) {
				this.scene.audioEnabled = false;
			} else {
				this.scene.audioEnabled = true;
			}
		} catch(e) {
			console.log(e);
		}
		this.changeSmallButtonImage(this.scene.audioEnabled ? this.soundOnImage : this.soundOffImage);
	}
	
	onToggleSound(e:any) {
		setTimeout(() => {
			if(this.soundToggle.classList.contains('active')) {
				this.scene.audioEnabled = true;
			} else {
				this.scene.audioEnabled = false;
			}
		}, 300);
	}
	
	onToggleInfinite(e:any) {
		setTimeout(() => {
			if(this.infiniteToggle.classList.contains('active')) {
				this.playInfinite = true;
			} else {
				this.playInfinite = false;
			}
		}, 300);
	}
	
	handleGoal(object:any) {
		// if(!this.playInfinite) {
		// 	this.showBonus(() => {
		// 		this.unpauseGame();
		// 		this.playInfinite = true; // on closing, set playInfinite true
		// 		this.infiniteToggle.classList.add('active');
		// 		this.showGoalImage();
		// 		this.updateBallUi(true);
		// 	});
		// } else {
			//@ts-ignore
			window.showconfetti();
			this.showGoalImage();
			this.updateBallUi(true);
		// }
	}
	
	showGoalImage() {
		if(++this.ballsInReserve > this.MAX_BALLS) {
			this.ballsInReserve = this.MAX_BALLS;
			this.backboardChangeImage(this.goalMaxBallBackboardImage, ()=> {
				setTimeout(() => {
					this.backboardGoBackToDefault();
				}, 1000);
			});
		} else {
			this.backboardChangeImage(this.goalNewBallBackboardImage, ()=> {
				setTimeout(() => {
					this.backboardGoBackToDefault();
				}, 1000);
			});
		}
	}
	
	showBonus(onClose:()=>void) { // aanpassen zodat de smartvertizing modal aangeroepen wordt, die de prijs toont
		if(!this.SHOW_POPUPS) {
			onClose();
			return;
		}
		this.pauseGame();
		$('#dialog_bonus').modal({backdrop: 'static', keyboard: false}) ;
		$('#dialog_bonus').on('hidden.bs.modal', () => {
			this.unpauseGame();
			this.playInfinite = true; // on closing, set playInfinite true
			this.infiniteToggle.classList.add('active');
			onClose();
		});
	}
	
	showGameover(onClose:()=>void) {
		if(!this.SHOW_POPUPS) {
			onClose();
			return;
		}
		this.pauseGame();
		$('#modal-lost').modal({backdrop: 'static', keyboard: false}) ;
		$('#modal-lost').on('hidden.bs.modal', () => {
			this.unpauseGame();
			this.infiniteToggle.classList.add('active');
			onClose();
		})
	}
	
	toggleHighscoreButton(show:boolean = false) {
		if(show) $('#btn-submitscore').show();
		else $('#btn-submitscore').hide();
	}
	
	showSubmitscore() {
		if(!this.SHOW_POPUPS) {
			return;
		}
		this.pauseGame();
		
		window.dispatchEvent(new CustomEvent('getPrizeBasedOnScore', {detail:{score:this.score, highscoreToBeat: this.highscoreToBeat}}));
	}
	
	resetScore() {	
		dispatchEvent(this.scoreResetEvent);
		this.score = 0;
		this.scoreElement.innerHTML = "" + this.score;
	}
	
	updateHighScore(highscores:any[]) {
		if(highscores) {
			highscores.sort((a,b) => {
				return b.score - a.score;
			});
			highscores = highscores.slice(0, 10);
			let lowestScore = 0;
			lowestScore = parseInt(highscores[0] ? highscores[0].score : "0");
			for(let score of highscores) {
				if(score.score != null) {
					lowestScore = Math.min(lowestScore, parseInt(score.score));
				}
			}
			this.highscoreToBeat = lowestScore;
		}
	}
	
	renderScene(ts:number) {
		// console.log('renderScene', ts);
		if(this.SHOW_FPS) this.updateFps();
		if(!this.gamePaused) {
			if(this.lastRenderRun == 0) this.lastRenderRun = 0;
			var delta = (ts - this.lastRenderRun) / 1000;
			if(this.ledboardTexture) this.ledboardTexture.uOffset += 0.5 * delta;
			if(this.pinBall && this.pinBall.position.x < -30) this.checkLightIntersection(0);
			this.engine.beginFrame();
			// this.engine.clear(this.CLEAR_COLOR, true, true);
			// console.log('this.lastActualRender', this.lastActualRender);
			// if(this.lastActualRender + delta > 1/1) {
				// console.log('actual render');
				this.scene.render(!this.introAnimationDone);
			// } else {
				// console.log('skip render');
				// this.lastActualRender += delta;
			// }
			this.engine.endFrame();
			this.lastRenderRun = ts;
			// if(moment().valueOf() > this.nextShine) buttonShine();
		}
	}
	
	subLoop(ts:number) {
		if(!this.gamePaused && this.introAnimationDone) {
			if(this.lastSubloopRun == 0) this.lastSubloopRun = 0;
			var delta = (ts - this.lastSubloopRun) / 1000;
			this.rightFlipper.setPowered(this.rightIsPowered);
			this.leftFlipper.setPowered(this.leftIsPowered);
			this.onRender(delta);
			
			this.lastSubloopRender = 0;
			
			this.lastSubloopRun = ts;
		}
	}
	
	animationFrame(ts:number) {
		// console.log('animationFrame');
		this.renderScene(ts);
		this.subLoop(ts);
		requestAnimationFrame((ts) => this.animationFrame(ts));
	}
	
	startGame() {
		// console.log('this.startGame');
		var endLoad = moment().valueOf();
		this.rotateMachine();
		this.unIndexMeshes();

		requestAnimationFrame((ts) => this.animationFrame(ts))
		
		
		this.unpauseGame();
		
		this.optimizer.start();
		
		if(this.isMobileDevice()) {
			this.setPresetCameraPos(9);
		} else {
			this.camera.position = new BABYLON.Vector3(-120, 35, 50);
			this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
		}
		
		// this.camera.rotation = new BABYLON.Vector3(0.24, 1.9, -0.0020);
		setTimeout(() => {
			this.backboardContext.drawImage(this.defaultBackboardImage, 0, 0);
			this.backboardTexture.update();
			this.freezeMeshWorldMeshes();
			this.addBall();
			this.setShadowMapResolution(this.currentShadowResolution);
			// scene.freezeActiveMeshes();
		}, 1000);
		
		setTimeout(() => {
			this.startOptimisingResolution();
			this.hideLoadingScreen();
			this.updateBallUi(true);
			this.engine.clear(this.CLEAR_COLOR, true, true);
			this.scene.audioEnabled = true;
			this.changeSmallButtonImage(this.soundOnImage);
			this.backgroundMusic.play();
			// setTimeout(() => backgroundMusic2.play(), 10000);
			if(this.SKIP_INTRO_ANIM) {
				this.setPresetCameraPos(this.currentCameraPreset);
				this.onIntroAnimationDone();
				if(this.loadingScreen) {
					this.loadingScreen.style.opacity = "0";
				}
			} else {
				setTimeout(() => this.animateCamera(), 1000);
			}
			
		}, 4500 - (endLoad - this.startLoad));
	}
	
	onIntroAnimationDone() {
		this.goaliePlane.position = this.getGoaliePlanePosition();
		this.eifel_tower.position = this.eifel_tower_position;
		this.big_ben.position = this.big_ben_position;
		this.pisa_tower.position = this.pisa_tower_position;
		this.mill.position = this.mill_position;
		
		this.introAnimationDone = true;
		this.gameStart.play();
		this.updateCamera();
	}
	
	updateBallUi(animate:boolean = false) {
		if(animate) {
			this.ballUi.addClass("animate");
			setTimeout(() => {
				this.ballUi.removeClass("animate");
			}, 1000);
		} else {
			this.ballUi.removeClass("animate");
		}
		this.ballCount.text(this.ballsInReserve);
	}
	
	hideLoadingScreen() {
		if(this.loadingContent && this.loadingContent.style) this.loadingContent.style.opacity = "0";
		this.canvas.style.opacity = "1";
	}
	
	buildScene(onDone:()=>void) {
		var stepsBeforeReady = 19;
		var checkLoadDone = () => {
			if(--stepsBeforeReady <= 0) {
				onDone();
			}
		}
		
		// let testbox = BABYLON.MeshBuilder.CreateBox("testbox", {width:2, height:2, depth:1, sideOrientation:BABYLON.Mesh.BACKSIDE}, this.scene);
		// testbox.position = PORTRAIT_CAMERA_TARGET;
		
		this.skybox = BABYLON.MeshBuilder.CreateBox("skybox", {width:250, height:250, depth:250, sideOrientation:BABYLON.Mesh.BACKSIDE}, this.scene);
		
		this.skyboxMaterial = new BABYLON.StandardMaterial("skybox", this.scene);
		// let cubeTexture = BABYLON.CubeTexture.CreateFromImages(["./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf()], this.scene, true);
		this.skyboxTexture = BABYLON.CubeTexture.CreateFromImages(["./assets/3d-models/Images/ek21-background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/ek21-floor.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/ek21-background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/ek21-background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/ek21-floor.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/ek21-background.jpg?nocache=" + moment().valueOf()], this.scene, true);
		
		this.skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
		this.skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
		this.skyboxMaterial.reflectionTexture = this.skyboxTexture;
		this.skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
		this.skyboxMaterial.freeze();
		this.skybox.rotation.y = Math.PI;

		this.skybox.position = new BABYLON.Vector3(0, 75, 65);
		this.skybox.material = this.skyboxMaterial;
		
		this.floor = BABYLON.MeshBuilder.CreateGround("floor", {width:72, height:114}, this.scene);
		this.floor.physicsImpostor = new BABYLON.PhysicsImpostor(this.floor, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction: this.FLOOR_FRICTION}, this.scene);
		
		if (this.shadows) this.floor.receiveShadows = true;
		this.floor.setParent(this.pinballMachine);
		this.floor.position.y = 0.2;
		this.floor.position.z = 10;
		this.meshesToUnIndex.push(this.floor);
		this.meshesToFreezeWorldMatrix.push(this.floor);
		
		this.grassTexture = new BABYLON.Texture("./assets/3d-models/Images/EK21-Pinball-grass-texture3.jpg?nocache=" + moment().valueOf(), this.scene);
		this.grassTexture.uScale = 10;
		this.grassTexture.vScale = 10;
		this.grassTexture.isCube = false;
		this.grassTexture.coordinatesMode = 2; // PLANAR_MODE
		
		this.grassMaterial = new BABYLON.StandardMaterial("grassMaterial", this.scene);
		this.grassMaterial.diffuseTexture = this.grassTexture;
		// grassMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
		this.grassMaterial.specularColor = new BABYLON.Color3(0.2,0.2,0.2);
		
		// //
		// var fieldlinesPlane = BABYLON.MeshBuilder.CreateGround("floor2", {width:72, height:114 }, this.scene);
		// fieldlinesPlane.position.y = 0.01;
		// // // fieldlinesPlane.isPickable = false;
		// if (shadows)
		// fieldlinesPlane.receiveShadows = true;
		// fieldlinesPlane.setParent(this.pinballMachine);
		// meshesToUnIndex.push(fieldlinesPlane);
		// this.meshesToFreezeWorldMatrix.push(fieldlinesPlane);
		//
		this.fieldLinesTexture = new BABYLON.Texture("./assets/3d-models/Images/ek21-pinball-fieldlines.png?nocache=" + moment().valueOf(), this.scene);
		this.fieldLinesTexture.hasAlpha = true;
		
		this.fieldLinesMaterial = new BABYLON.StandardMaterial("fieldLinesMaterial", this.scene);
		this.fieldLinesMaterial.diffuseTexture = this.fieldLinesTexture;
		this.fieldLinesMaterial.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
		this.fieldLinesMaterial.useAlphaFromDiffuseTexture = true;
		this.fieldLinesMaterial.zOffset = -1;
		// this.fieldLinesMaterial.disableDepthWrite = true;
		// this.fieldLinesMaterial.needDepthPrePass = false;
		this.fieldLinesMaterial.freeze();
		
		this.floor.alphaIndex = 1;
		this.floor.material = this.fieldLinesMaterial;
		
		this.ceiling = BABYLON.MeshBuilder.CreateBox("ceiling", {width:72, height:1, depth:110}, this.scene);
		this.ceiling.physicsImpostor = new BABYLON.PhysicsImpostor(this.floor, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction: 0.01}, this.scene);
		this.ceiling.position.y = 9;
		this.ceiling.visibility = this.PHYSICS_MODELS_VISIBILITY;
		this.ceiling.isPickable = false;
		this.ceiling.setParent(this.pinballMachine);
		this.meshesToUnIndex.push(this.ceiling);
		this.meshesToFreezeWorldMatrix.push(this.ceiling);
		
		this.frontwall = BABYLON.MeshBuilder.CreateBox("frontwall", {width:72, height:90, depth:1}, this.scene);
		// frontwall.physicsImpostor = new BABYLON.PhysicsImpostor(frontwall, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0.8, friction: 0.01, }, this.scene);
		this.frontwall.position.z = this.BOTTOM_POS_Z;
		this.frontwall.visibility = this.PHYSICS_MODELS_VISIBILITY;
		this.frontwall.isPickable = false;
		this.meshesToUnIndex.push(this.frontwall);
		this.meshesToFreezeWorldMatrix.push(this.frontwall);
		this.frontwall.setParent(this.pinballMachine);
		
		this.ballTexture = new BABYLON.Texture("./assets/3d-models/Images/soccer-ball.jpg?nocache=" + moment().valueOf(), this.scene);
		this.ballMaterial = new BABYLON.StandardMaterial("metalMaterial", this.scene);
		this.ballTexture.coordinatesMode = 1;
		this.ballMaterial.alpha = 0;
		this.ballMaterial.diffuseTexture = this.ballTexture;
		this.ballMaterial.specularColor = new BABYLON.Color3(1,1,1);
		this.ballMaterial.freeze();
		
		var simpleMaterial = new BABYLON.StandardMaterial("simpleMaterial", this.scene);
		simpleMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
		simpleMaterial.specularColor = new BABYLON.Color3(1,1,1);
		simpleMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
		simpleMaterial.wireframe = true;
		simpleMaterial.freeze();
		
		
		let goalieImage = this.getRandomGoalie();
		
		this.goalObstacle = this.createObstacle(this.scene, new BABYLON.Vector3(0, 5, -46), 30, 10, 1);
		
		this.goaliePlane = BABYLON.MeshBuilder.CreatePlane("goaliePlane", {height:9, width:9}, this.scene);
		this.goaliePlane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
		this.goaliePlane.position = this.getGoaliePlanePosition().clone();
		this.goaliePlane.position.y += 300;
		this.goaliePlane.isPickable = false;
		this.goalieObstacle = BABYLON.MeshBuilder.CreateBox("goalieObstacle", {height: 10, width:9, depth:1}, this.scene);
		this.goaliePlane.setParent(this.goalieObstacle);
		this.goaliePlane.alphaIndex = 9;
		this.goalieObstacle.visibility = this.OBSTACLES_MODELS_VISIBILITY;
		this.goalieObstacle.position = new BABYLON.Vector3(0,3,-39);
		this.goalieObstacle.rotationQuaternion = BABYLON.Quaternion.RotationAxis(this.yAxis, Math.PI);
		this.goalieObstacle.isPickable = false;
		
		this.goalieObstacle.physicsImpostor = new BABYLON.PhysicsImpostor(this.goalieObstacle, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
		this.goalieObstacle.setParent(this.pinballMachine);
		this.meshesToUnIndex.push(this.goalieObstacle);
		this.goalieTexture = new BABYLON.Texture("./assets/3d-models/Images/"+goalieImage+"?nocache=" + moment().valueOf(), this.scene);
		this.goalieTexture.hasAlpha = true;
		this.goalieTexture.coordinatesMode = 4;
		this.goalieTexture.isCube = false;
		this.goalieTexture.invertZ = true;
		this.goalieMaterial = new BABYLON.StandardMaterial("goalieMaterial", this.scene);
		this.goalieMaterial.zOffset = -99;
		this.goalieMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
		this.goalieMaterial.specularColor = new BABYLON.Color3(0,0,0);
		this.goalieMaterial.diffuseTexture = this.goalieTexture;
		this.goalieMaterial.useAlphaFromDiffuseTexture = true;
		this.goalieMaterial.freeze();
		this.goaliePlane.material = this.goalieMaterial;
		this.goaliePlane.rotationQuaternion = BABYLON.Quaternion.RotationAxis(this.yAxis, 0);
		
		this.defaultBackboardImage = new Image();
		this.goalNewBallBackboardImage = new Image();
		this.goalMaxBallBackboardImage = new Image();
		this.backboardTexture = new BABYLON.DynamicTexture("backboardTexture", {width:1170, height:445}, this.scene, false);
		this.backboardMaterial = new BABYLON.StandardMaterial('backboardMaterial', this.scene);
		this.backboardMaterial.diffuseTexture = this.backboardTexture;
		this.backboardMaterial.diffuseColor = new BABYLON.Color3(0.64,0.64,0.64);
		this.backboardMaterial.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
		this.backboardContext = this.backboardTexture.getContext();
		this.defaultBackboardImage.onload = () => {
			this.backboardContext.drawImage(this.defaultBackboardImage, 0, 0);
			this.backboardTexture.update();
			checkLoadDone();
		}
		this.goalNewBallBackboardImage.onload = ()=> { checkLoadDone();};
		this.goalMaxBallBackboardImage.onload = ()=> { checkLoadDone();};
		this.defaultBackboardImage.src = "./assets/3d-models/Images/ek21-pinball-backdisplay-default.jpg?nocache=" + moment().valueOf();
		this.goalNewBallBackboardImage.src = "./assets/3d-models/Images/ek21-pinball-backdisplay-goal.jpg?nocache=" + moment().valueOf();
		this.goalMaxBallBackboardImage.src = "./assets/3d-models/Images/ek21-pinball-backdisplay-goal-no-ball.jpg?nocache=" + moment().valueOf();
		
		// this.buttonShineTexture = new BABYLON.Texture("./assets/3d-models/Images/button-shine.png?nocache=" + moment().valueOf(), this.scene);
		// this.buttonShineTexture.hasAlpha = true;
		// this.buttonShineTexture.uOffset = 1;
		// this.buttonShineTexture.vOffset = 1;

		this.soundOnImage = new BABYLON.Texture("./assets/3d-models/Images/EK21-Pinball-Button-geluid-aan.png?nocache=" + moment().valueOf(), this.scene);
		this.soundOffImage = new BABYLON.Texture("./assets/3d-models/Images/EK21-Pinball-Button-geluid-uit.png?nocache=" + moment().valueOf(), this.scene);

		//
		// rightButtonShineMaterial = new BABYLON.StandardMaterial('rightButtonShineMaterial', this.scene);
		// rightButtonShineMaterial.diffuseTexture = buttonShineTexture;
		// rightButtonShineMaterial.useAlphaFromDiffuseTexture = true;
		// rightButtonShineMaterial.alpha = 0;
		// this.leftButtonShineMaterial = new BABYLON.StandardMaterial('leftButtonShineMaterial', this.scene);
		// this.leftButtonShineMaterial.diffuseTexture = this.buttonShineTexture;
		// this.leftButtonShineMaterial.useAlphaFromDiffuseTexture = true;
		// this.leftButtonShineMaterial.alpha = 0;
		
		
		this.leftButtonSbTexture = new BABYLON.Texture("./assets/3d-models/Images/ek21-button-enter-txt.png?nocache=" + moment().valueOf(), this.scene);
		this.leftButtonSbTexture.hasAlpha = true;
		this.leftButtonSbMaterial = new BABYLON.StandardMaterial('leftButtonSbMaterial', this.scene);
		this.leftButtonSbMaterial.alphaMode = BABYLON.Engine.ALWAYS;
		this.leftButtonSbMaterial.diffuseTexture = this.leftButtonSbTexture;
		this.leftButtonSbMaterial.specularColor = new BABYLON.Color3(0,0,0)
		this.leftButtonSbMaterial.useAlphaFromDiffuseTexture = true;
		this.leftButtonSbMaterial.freeze();
		
		this.ledboardTexture = new BABYLON.Texture('./assets/3d-models/Images/EK21--pinball-ledboard.png', this.scene);
		this.ledboardMaterial = new BABYLON.StandardMaterial('ledboardMaterial', this.scene);
		this.ledboardMaterial.diffuseTexture = this.ledboardTexture;
		
		this.goalNetTexture = new BABYLON.Texture('./assets/3d-models/Images/goal-net.png', this.scene);
		this.goalNetTexture.hasAlpha = true;
		this.goalNetMaterial = new BABYLON.StandardMaterial('goalNetMaterial', this.scene);
		this.goalNetMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
		this.goalNetMaterial.diffuseTexture = this.goalNetTexture;
		this.goalNetMaterial.useAlphaFromDiffuseTexture = true
		if(this.CONSOLE_DEBUGGING) this.win.goalNetMaterial = this.goalNetMaterial;
		
		//
		// smallButtonShineMaterial = new BABYLON.StandardMaterial('smallButtonShineMaterial', this.scene);
		// smallButtonShineMaterial.diffuseTexture = this.buttonShineTexture;
		// smallButtonShineMaterial.useAlphaFromDiffuseTexture = true;
		// smallButtonShineMaterial.alpha = 0;
		
		// var cube = BABYLON.MeshBuilder.CreateBox('cube', {width: 6, height:10, depth: 144}, this.scene);
		// cube.setParent(this.pinballMachine);
		// cube.material = simpleMaterial;		
		
		if(this.CONSOLE_DEBUGGING) {
		} else { // conflicts with console positioning so only add if CONSOLE_DEBUGGING = off
		}
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "fullcase-optimised.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			var centerpos = new BABYLON.Vector3(0,0,0);
			var pinBallCase = new BABYLON.TransformNode("pinballCase", this.scene);
			// pinBallCase.setParent(this.pinballMachine);
			for(let mesh of meshes) {
				mesh.scaling.x = -1;
				mesh.setParent(pinBallCase);
				mesh.position = centerpos;
				mesh.visibility = this.GRAPHIC_MODELS_VISIBILITY;
				if(mesh.material.name == 'Inner_wall_logo') {
					mesh.material = this.ledboardMaterial;
				} else if(mesh.material.name == 'Backboard') {
					mesh.material = this.backboardMaterial;
				} else if(mesh.material.name == 'grassmat') {
					mesh.receiveShadows = true;
					mesh.material.freeze();
				} else {
					mesh.material.freeze();
				}
				// else if(mesh.material.name == 'leds') {
				// 	backwall = mesh;
				// }
				mesh.alphaIndex = 0;
				this.meshesToUnIndex.push(mesh);
				this.meshesToFreezeWorldMatrix.push(mesh);
			}
			
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "obstacle-1.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.eifel_tower = meshes[0];
			
			this.eifel_tower.scaling.x = this.eifel_tower.scaling.y = this.eifel_tower.scaling.z = this.isPhoneDevice() ? 1.5 : 1.2;
			
			this.eifel_tower.position.copyFrom(this.eifel_tower_position);
			this.eifel_tower.position.y = 300;
			
			this.eifel_tower.rotation.y = 0.6 * Math.PI;
			this.eifel_tower.physicsImpostor = new BABYLON.PhysicsImpostor(this.eifel_tower, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			this.eifel_tower.setParent(this.pinballMachine);
			this.eifel_tower.material.freeze();
			// this.eifel_tower.receiveShadows = true;
			// // @ts-ignore
			// window.eifel = this.eifel_tower;
			this.shadowMeshes.push(this.eifel_tower);
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "obstacle-2.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.big_ben = meshes[0];
			this.big_ben.scaling.x = this.big_ben.scaling.y = this.big_ben.scaling.z = this.isPhoneDevice() ? 1.5 : 1.2;
			this.big_ben.material.backFaceCulling = false;
			this.big_ben.position.copyFrom(this.big_ben_position);
			this.big_ben.position.y = 300;
			this.big_ben.rotation.y = 0.6 * Math.PI;
			this.big_ben.physicsImpostor = new BABYLON.PhysicsImpostor(this.big_ben, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			// this.big_ben.receiveShadows = true;
			this.big_ben.setParent(this.pinballMachine);
			this.big_ben.material.freeze();
			// // @ts-ignore
			// window.bigBen = this.big_ben;
			this.shadowMeshes.push(this.big_ben);
			checkLoadDone();
		});					
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "obstacle-3.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.pisa_tower = meshes[0];
			this.pisa_tower.scaling.x = this.pisa_tower.scaling.y = this.pisa_tower.scaling.z = this.isPhoneDevice() ? 1.5 : 1.2;
			this.pisa_tower.position.copyFrom(this.pisa_tower_position);
			this.pisa_tower.position.y = 300;
			this.pisa_tower.rotation.y = 1.6 * Math.PI;
			this.pisa_tower.physicsImpostor = new BABYLON.PhysicsImpostor(this.pisa_tower, BABYLON.PhysicsImpostor.CylinderImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			this.pisa_tower.setParent(this.pinballMachine);
			this.pisa_tower.material.freeze();
			// this.pisa_tower.receiveShadows = true;
			this.shadowMeshes.push(this.pisa_tower);
			// // @ts-ignore
			// window.pisa = this.pisa_tower;
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "obstacle-4.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.mill = meshes[0];
			meshes[1].setParent(this.mill);
			meshes[2].setParent(this.mill);
			meshes[3].setParent(this.mill);
			this.mill.scaling.x = this.mill.scaling.y = this.mill.scaling.z = this.isPhoneDevice() ? 1.5 : 1.2;
			
			this.mill.position.copyFrom(this.mill_position);
			this.mill.position.y = 300;
			this.mill.rotation.y = -0.1 * Math.PI;
			this.mill.physicsImpostor = new BABYLON.PhysicsImpostor(this.mill, BABYLON.PhysicsImpostor.CylinderImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			this.mill.setParent(this.pinballMachine);
			meshes[0].material.freeze();
			meshes[1].material.freeze();
			meshes[2].material.freeze();
			meshes[3].material.freeze();
			// this.mill.receiveShadows = true;
			// //@ts-ignore 
			// window.mill = this.mill;
			this.shadowMeshes.push(this.mill);
			checkLoadDone();
		});
		
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "soccerball_low.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.soccerBall.setEnabled(false);
			for(let mesh of meshes) {
				mesh.setParent(this.soccerBall);
			}
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "plunger.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.plunger = meshes[0];
			meshes[1].setParent(this.plunger);
			this.plunger.position = new BABYLON.Vector3(this.plungerMax.x, this.plungerMax.y, this.plungerMax.z);
			this.plunger.physicsImpostor = new BABYLON.PhysicsImpostor(this.plunger, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction: this.WALL_FRICTION}, this.scene);
			this.plunger.setParent(this.pinballMachine);
			// this.plunger.isPickable = false;
			this.meshesToUnIndex.push(this.plunger);
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "right-button.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.rightButton = new BABYLON.TransformNode("rightButton", this.scene);
			meshes[0].setParent(this.rightButton);
			meshes[1].setParent(this.rightButton);
			this.rightButtonMeshes = meshes;
			for( let mesh of this.rightButtonMeshes) {
				mesh.name = "rightButton_mesh_" + this.rightButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
				// if(mesh == meshes[1]) {
				// 	let multiMaterial = new BABYLON.MultiMaterial('rightButtonMultiMaterial', this.scene);
				// 	multiMaterial.subMaterials = [mesh.material, rightButtonShineMaterial];
				// 	var verticesCount = mesh.getTotalVertices();
				// 	mesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, 606, mesh));
				// 	mesh.material = multiMaterial;
				// }
			}
			this.rightButton.position = new BABYLON.Vector3(-18, 3.25, 49);
			this.rightButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.rightButton);
			this.meshesToFreezeWorldMatrix.push(this.rightButton);
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "left-button.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.leftButton = new BABYLON.TransformNode("leftButton", this.scene);
			meshes[0].setParent(this.leftButton);
			meshes[1].setParent(this.leftButton);
			this.leftButtonMeshes = meshes;
			for( let mesh of this.leftButtonMeshes) {
				mesh.name = "leftButton_mesh_" + this.leftButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
				if(mesh == meshes[1]) {
					let multiMaterial = new BABYLON.MultiMaterial('leftButtonMultiMaterial', this.scene);
					multiMaterial.subMaterials = [mesh.material, this.leftButtonShineMaterial, this.leftButtonSbMaterial];
					var verticesCount = mesh.getTotalVertices();
					mesh.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 606, mesh));
					mesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, 606, mesh));
					mesh.subMeshes.push(new BABYLON.SubMesh(2, 0, verticesCount, 0, 606, mesh));
					for(let i = 0; i < mesh.subMeshes.length; i++) {
						mesh.subMeshes[i].getRenderingMesh().alphaIndex = i * 2 + 10;
					}
					mesh.material = multiMaterial;
				}
			}
			this.leftButton.position = new BABYLON.Vector3(26, 3.25, 49);
			this.leftButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.leftButton);
			this.meshesToFreezeWorldMatrix.push(this.leftButton);
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "left-button-small.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.smallButton = new BABYLON.TransformNode("smallButton", this.scene);
			meshes[0].setParent(this.smallButton);
			meshes[1].setParent(this.smallButton);
			this.smallButtonMeshes = meshes;
			for( let mesh of this.smallButtonMeshes) {
				mesh.name = "smallButton_mesh_" + this.smallButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
				// if(mesh == meshes[0]) {
				// 	let multiMaterial = new BABYLON.MultiMaterial('smallButtonMultiMaterial', this.scene);
				// 	multiMaterial.subMaterials = [mesh.material, smallButtonShineMaterial];
				// 	////console.log(mesh.subMeshes);
				// 	var verticesCount = mesh.getTotalVertices();
				// 	mesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, 606, mesh));
				// 	mesh.material = multiMaterial;
				// }
			}
			this.smallButton.position = new BABYLON.Vector3(17.5, 3.25, 49);
			this.smallButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.smallButton);
			this.meshesToFreezeWorldMatrix.push(this.smallButton);
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh("", "./assets/3d-models/", "new-goal-2.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			// BABYLON.SceneLoader.ImportMesh("", "./assets/3d-models/", "Soccer-Goal.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.goal = new BABYLON.TransformNode("goal", this.scene);
			
			for(let mesh of meshes) {
				mesh.setParent(this.goal);
				mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, {mass:0, restitution: 0, friction: this.WALL_FRICTION}, this.scene);
				mesh.isPickable = false;
				this.meshesToUnIndex.push(mesh);
				if(!this.CONSOLE_DEBUGGING) this.meshesToFreezeWorldMatrix.push(mesh);
				if(mesh.material.name == "Net") {
					mesh.alphaIndex = 1;
					mesh.material = this.goalNetMaterial;
					//
					// for (let texture of mesh.material.getRenderTargetTextures()) {
					// 	texture.hasAlpha = true;
					// }
					// mesh.material.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
				}
			}
			// meshes[0].setParent(this.goal);
			// meshes[1].setParent(this.goal);
			var posy = 0;
			this.goal.setParent(this.pinballMachine);
			this.goal.position = new BABYLON.Vector3(0,posy,-44);
			// goal.rotationQuaternion = BABYLON.Quaternion.RotationAxis(yAxis, 0);
			
			if(this.isPhoneDevice()) {
				this.goal.rotationQuaternion = BABYLON.Quaternion.RotationAxis(this.xAxis, -Math.PI * 0.09);
				this.goal.position.y += 1;
				this.goal.position.z += 1;
				if(this.CONSOLE_DEBUGGING) {
					this.win.goalRotation = this.goal.rotationQuaternion;
				}
			}
			this.goalmeshes = meshes;
			
			if(this.CONSOLE_DEBUGGING) {
				this.win.goal = this.goal;
			}
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "chevron-light.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.chevronLight = meshes[0];
			this.chevronLight.setParent(this.pinballMachine);
			let startPos = this.BALL_START_POSITION.z + 1.5;
			this.chevronLight.position = new BABYLON.Vector3(-27, 3.2, startPos);
			this.chevronLight.name = "chevronLight_0";
			this.chevronLights.push(this.chevronLight);
			this.meshesToUnIndex.push(this.chevronLight);
			this.meshesToFreezeWorldMatrix.push(this.chevronLight);
			let triggerPoint = this.BALL_START_POSITION.clone();
			triggerPoint.z = this.chevronLight.position.z + 0.5;
			triggerPoint.y = 1;
			this.chevronTriggers.push(triggerPoint);
			for(let i = 0; i < 11; i++) {
				var index = i + 1;
				let light = this.chevronLight.clone("chevronLight_"+index, this.pinballMachine);
				light.material = light.material.clone("chevronLightMat_"+index);
				light.position.z = startPos - index * 5;
				this.chevronLights.push(light);
				this.meshesToUnIndex.push(light);
				this.meshesToFreezeWorldMatrix.push(light);
				let triggerPoint = this.BALL_START_POSITION.clone();
				triggerPoint.z = light.position.z + 0.5;
				triggerPoint.y = 1 ;
				this.chevronTriggers.push(triggerPoint);
			}
			// caseLight1.excludedMeshes = this.chevronLights;
			
			checkLoadDone();
		});
		
		BABYLON.SceneLoader.ImportMesh(null, "./assets/3d-models/", "case-physics.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			var posy = 3;
			
			this.casePhysics = meshes[0];
			// this.casePhysics.material.wireframe = true;
			this.casePhysics.visibility = this.PHYSICS_MODELS_VISIBILITY;
			
			this.casePhysics.position = new BABYLON.Vector3(0,0,0);
			this.casePhysics.isPickable = false;
			this.casePhysics.physicsImpostor = new BABYLON.PhysicsImpostor(this.casePhysics, BABYLON.PhysicsImpostor.MeshImpostor, {mass:0, restitution:this.BOUNDS_RESTITUTION, friction: this.WALL_FRICTION}, this.scene);
			this.casePhysics.setParent(this.pinballMachine);
			
			this.meshesToUnIndex.push(this.casePhysics);
			this.meshesToFreezeWorldMatrix.push(this.casePhysics);
			checkLoadDone();
			
			// (async() => {
			let functionalFlipper = async (right: boolean) => {
				let fac = right ? 1 : -1;
				let flip = await createFlipper("FF" + (right ? "R" : "L"), this.scene, right, new BABYLON.Vector3(-9.5 * fac, 0.0, 50), this.machineAngle, this.casePhysics.physicsImpostor, this.flipperSound);
				flip.graphic.isPickable = false;
				this.onRenderList.push(() => flip.update());
				this.shadowMeshes.push(flip.graphic);
				// var win:any = window;
				if(right) {
					this.rightFlipper = flip;
					// this.win.right = rightFlipper;
				} else {
					this.leftFlipper = flip;
					// this.win.left = leftFlipper;
				}
				
				if (this.FLIPPER_AUTOFIRE) {
					// auto fire
					setTimeout(() => {
						setInterval(() => {
							flip.setPowered(true);
							setTimeout(() => {
								flip.setPowered(false);
							}, 500);
						}, 1000);
					}, right ? 0 : 500);
				}
				checkLoadDone();
			}
			functionalFlipper(true);
			functionalFlipper(false);
			// })();
		});
		
		let texturesToLoad = [this.ledboardTexture, this.grassTexture, this.fieldLinesTexture, this.goalNetTexture, this.ballTexture, this.goalieTexture, this.backboardTexture, this.leftButtonSbTexture, this.soundOffImage, this.soundOnImage, this.skyboxTexture];
		let texturesLoaded = 0;
		for(let texture of texturesToLoad) {
			if(texture && texture.isReady) {
				texturesLoaded++;
			}
		}
		if(texturesLoaded >= texturesToLoad.length) {
			checkLoadDone();
		} else {
			BABYLON.Texture.WhenAllReady(texturesToLoad, () => {
				checkLoadDone();
			});
		}
	}
	
	backboardChangeImage(image:any, onDone:()=>void = null) {
		this.backboardAnimating = true;
		this.currentBackboardImage = image;
		requestAnimationFrame(() => { this.animateBackboard(onDone); });
	}

	changeSmallButtonImage(image:any) {
		this.currentSmallButtonImage = image;
		requestAnimationFrame(() => { 
			// @ts-ignore
			this.smallButtonMeshes[0].material.diffuseTexture = image;
		});
	}
	
	backboardGoBackToDefault() {
		this.backboardChangeImage(this.defaultBackboardImage);
	}
	
	animateBackboard(onDone:()=>void = null) {
		this.backboardContext.globalAlpha = this.backboardOpacity;
		this.backboardContext.drawImage(this.currentBackboardImage, 0, 0);
		this.backboardOpacity += 0.01;
		this.backboardTexture.update();
		if (this.backboardOpacity < 1) {
			requestAnimationFrame(() => { this.animateBackboard(onDone); });
		}
		else {
			this.backboardOpacity = 0;
			this.backboardAnimating = false;
			if(onDone) onDone();
		}
	}
	
	checkObstacleCollisions() {
		if(this.collisionObjects && this.collisionObjects.length > 0 && this.pinBall) {
			var ballX =  this.pinBall.position.x;
			var ballZ = this.pinBall.position.z;
			if(ballX < -30) return;
			if(ballX < 0 && ballZ < 0) {
				this.checkObstacleCollision(this.collisionObjects[3], this.pinBall);
				this.checkObstacleCollision(this.collisionObjects[4], this.pinBall);
			} else if(ballX > 0 && ballZ < 0) {
				this.checkObstacleCollision(this.collisionObjects[2], this.pinBall);
				this.checkObstacleCollision(this.collisionObjects[4], this.pinBall);
			} else if(ballX > 0 && ballZ > 0) {
				this.checkObstacleCollision(this.collisionObjects[1], this.pinBall);
			} else {
				this.checkObstacleCollision(this.collisionObjects[0], this.pinBall);
			}
		}
	}
	
	checkObstacleCollision(obstacle:any, pinBall:BABYLON.Mesh) {
		if(this.physicsEngine._isImpostorPairInContact(obstacle.object.physicsImpostor, pinBall.physicsImpostor)) {
			if(obstacle.cooldown != 0) {
				var now = moment().valueOf();
				if(now > obstacle.lastTrigger + obstacle.cooldown) {
					obstacle.lastTrigger = now;
				} else {
					return;
				}
			}
			var points = obstacle.points;
			var func = obstacle.func;
			var isGoal = obstacle.isGoal;
			var sound = obstacle.sound;
			
			if(sound) sound.play();
			this.scoreAnimation(points, obstacle.object.position, obstacle.object);
			
			this.score += points;
			this.scoreElement.innerHTML = "" + this.score;
			if (typeof func == "function") {
				func(obstacle);
			}
		}
	}
	
	createEventListeners() {
		window.onresize = () => {
			this.updateSceneSize();
		};
		// window.addEventListener("blur", pauseGame);
		// window.addEventListener("focus", unpauseGame);
		this.canvas.onresize = () => {
			this.updateSceneSize();
		};
		
		if(this.md.is('ios')) { // because crapple
			this.canvas.ontouchstart=(ev) => this.handleTouchStart(ev);
			this.canvas.ontouchmove=(ev) => this.handleTouchMove(ev);
			this.canvas.ontouchend=(ev) => this.handleTouchEnd(ev);
		} else  { // because chrome
			this.canvas.onpointerup=(ev) => this.handleTouchEnd(ev);
			this.canvas.onpointermove=(ev) => this.handleTouchMove(ev);
			this.canvas.onpointerdown=(ev) => this.handleTouchStart(ev);
		}
		
		this.canvas.onmousedown = (ev) => this.handleTouchStart(ev);
		this.canvas.onmouseup =	(ev) => this.handleTouchEnd(ev);
		
		this.canvas.onkeydown = (ev) => this.onKeyDown(ev);
		
		this.canvas.onkeyup = (ev) => this.onKeyUp(ev);
		
		window.onscroll = (e:any) => {
			if(this.cameraControlsActive) return;
			e.preventDefault();
		};
		document.body.onscroll = (e:any) => {
			if(this.cameraControlsActive) return;
			e.preventDefault();
		};
		
		// this.canvas.addEventListener('touchstart', (e) => {
		// 	// console.log('touchstart', e);
		// 	if(e.changedTouches[0].clientX < window.innerWidth/2) {
		// 		// e.preventDefault();
		// 		this.leftIsPowered = true;
		// 	} else if(e.changedTouches[0].clientX > window.innerWidth/2 && e.changedTouches[0].clientX < window.innerWidth*0.9) {
		// 		// e.preventDefault();
		// 		this.rightIsPowered = true;
		// 	}
		//
		// 	BABYLON.Engine.audioEngine.audioContext.resume();
		// 	BABYLON.Engine.audioEngine.unlock();
		// }, {passive: true});
		
		// this.canvas.addEventListener('touchend', (e) => {
		// 	// console.log('touchend', e);
		// 	if(e.changedTouches[0].clientX < window.innerWidth/2) {
		// 		// e.preventDefault();
		// 		this.leftIsPowered = false;
		// 	} else if(e.changedTouches[0].clientX > window.innerWidth/2 && e.changedTouches[0].clientX < window.innerWidth*0.9) {
		// 		// e.preventDefault();
		// 		this.rightIsPowered = false;
		// 	}
		//
		// 	BABYLON.Engine.audioEngine.audioContext.resume();
		// 	BABYLON.Engine.audioEngine.unlock();
		// }, {passive: true});
		
		
		this.canvas.onclick= (e) => {
			BABYLON.Engine.audioEngine.audioContext.resume();
			BABYLON.Engine.audioEngine.unlock();
		};
	}
	
	handleTouchStart(e:any) {
		if(this.cameraControlsActive) return;
		let mouseLocation = this.getEventLocation(e);
		
		let code = Math.random();
		let eventId = this.getEventId(e);
		var pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
			if(!mesh.isPickable) return false
			if (mesh == this.leftButtonMeshes[0] || mesh == this.leftButtonMeshes[1] || mesh == this.smallButtonMeshes[0] || mesh == this.smallButtonMeshes[1] || mesh == this.rightButtonMeshes[0] || mesh == this.rightButtonMeshes[1]) {
				return true;
			}
			return false;
		});
		if (pickInfo.hit && !this.draggingPlunger) {
			if (pickInfo.pickedMesh == this.rightButtonMeshes[0] || pickInfo.pickedMesh == this.rightButtonMeshes[1]) {
				// this.arrowIsDown = true;
				this.launchBall();
			} else if (pickInfo.pickedMesh == this.leftButtonMeshes[0] || pickInfo.pickedMesh == this.leftButtonMeshes[1]) {
				this.openUrlFromSettings();
			} else if(pickInfo.pickedMesh == this.smallButtonMeshes[0] || pickInfo.pickedMesh == this.smallButtonMeshes[1]) {
				this.toggleSound();
			}
			
		} else {
			if(this.pinBall && this.pinBall.position.x < -30 && this.pinBall.position.z > 0) {
				if(this.draggingId == null) {
					this.draggingPlunger = true;
					this.draggingId = eventId;
					this.dragStart = mouseLocation.y;
					this.dragDistance = 0;
				}
			} else {
				if(mouseLocation.x < window.innerWidth/2) {
					this.leftIsPowered = true;
					if(eventId != null) this.leftUpId.push(eventId);
				} else if(mouseLocation.x > window.innerWidth/2) {
					this.rightIsPowered = true;
					if(eventId != null) this.rightUpId.push(eventId);
				}
			}
		}
	}
	
	getEventLocation(e:any) {
		if(e.type.indexOf('touch') > -1) {
			let ev = e.changedTouches && e.changedTouches[e.changedTouches.length -1] ? e.changedTouches[e.changedTouches.length -1] : null;
			if(ev) {
				return {
					x: ev.clientX,
					y: ev.clientY
				}
			}
		} else {
			return {
				x: e.clientX,
				y: e.clientY
			}
		}
		return null;
	}
	
	getEventId(e:any) {
		if(e.type.indexOf('touch') > -1) {
			return e.changedTouches && e.changedTouches[e.changedTouches.length -1] ? e.changedTouches[e.changedTouches.length -1].identifier : null;
		} else {
			return e.pointerId ? e.pointerId : null;
		}
		return null;
	}
	
	handleTouchMove(e:any) {
		if(this.cameraControlsActive) return;
		let mouseLocation = this.getEventLocation(e);
		let eventId = this.getEventId(e);
		if(this.draggingId == eventId) {
			this.dragDistance = (this.dragStart- mouseLocation.y) / 10;
		}
	}
	
	handleTouchEnd(e:any) {
		if(this.cameraControlsActive) return;
		let code = Math.random();
		let eventId = this.getEventId(e);
		let mouseLocation = this.getEventLocation(e);
		
		var pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
			if(!mesh.isPickable) return false
			if (mesh == this.leftButtonMeshes[0] || mesh == this.leftButtonMeshes[1] || mesh == this.smallButtonMeshes[0] || mesh == this.smallButtonMeshes[1] || mesh == this.rightButtonMeshes[0] || mesh == this.rightButtonMeshes[1]) {
				return true;
			}
			return false;
		});
		if (!pickInfo.hit) {
			if(this.leftIsPowered) {
				// e.preventDefault();
				if(eventId != null && this.leftUpId.indexOf(eventId)  > -1) {
					this.leftIsPowered = false;
					this.leftUpId.splice(this.leftUpId.indexOf(eventId), 1)
				} else {
					this.leftIsPowered = false;
				}
			} else if(this.rightIsPowered) {
				// e.preventDefault();
				if(eventId != null && this.rightUpId.indexOf(eventId)  > -1) {
					this.rightIsPowered = false;
					this.rightUpId.splice(this.rightUpId.indexOf(eventId), 1)
				} else {
					this.rightIsPowered = false;
				}
			}
			
		}
		if(this.draggingId == eventId) {
			this.draggingId = null;
			this.draggingPlunger = false;
		}
	}
	
	onKeyDown(event:any) {
		if(this.cameraControlsActive) return;
		switch (event.code) {
			case "ArrowDown":
			case "Space":
			event.preventDefault();
			this.arrowIsDown = true;
			break;
			case "ArrowLeft":
			case "KeyA":
			event.preventDefault();
			this.leftIsPowered = true;
			break;
			case "ArrowRight":
			case "KeyD":
			event.preventDefault();
			this.rightIsPowered = true;
			break;
		}
		BABYLON.Engine.audioEngine.unlock();
	}
	
	onKeyUp(event:any) {
		if(this.cameraControlsActive) return;
		switch (event.code) {
			case "ArrowDown":
			case "Space":
			event.preventDefault();
			this.arrowIsDown = false;
			break;
			case "ArrowLeft":
			case "KeyA":
			event.preventDefault();
			this.leftIsPowered = false;
			break;
			case "ArrowRight":
			case "KeyD":
			event.preventDefault();
			this.rightIsPowered = false;
			break;
		}
		BABYLON.Engine.audioEngine.unlock();
	}
	
	openUrlFromSettings()
	{
		var rawFile = new XMLHttpRequest();
		rawFile.open("GET", 'config.json?nocache=' + moment().valueOf(), false);
		var validUrl = /((https?|ftp|file):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/;
		rawFile.onreadystatechange = () =>
		{
			if(rawFile.readyState === 4)
			{
				if(rawFile.status === 200 || rawFile.status == 0)
				{
					var allText = rawFile.responseText;
					let obj = JSON.parse(allText)
					if(validUrl.test(obj.redirect_url)) {
						window.open(obj.redirect_url);
						return;
					}
				}
			}
			window.open('https://www.musiskwartier.nl/'); // fallback
		}
		rawFile.send(null);
	}
	
	launchBall() {
		this.quicklaunch = true;
		this.quicklaunchTime = moment().valueOf() + 250;
	}
	
	rotateMachine() {
		this.pinballMachine.rotationQuaternion = BABYLON.Quaternion.RotationAxis(new BABYLON.Vector3(1,0,0), this.machineAngle);
	}	
	
	pauseGame() {
		this.gamePaused = true;
		try {
			if(this.backgroundMusic && this.backgroundMusic.isPlaying) this.backgroundMusic.stop();
		} catch(e) {
			console.log(e);
		}
	}
	
	unpauseGame() {
		this.lastSubloopRun = 0;
		var now = moment().valueOf();
		if(this.collisionObjects) {
			for(let obj of this.collisionObjects) {
				obj.lastTrigger = now;
			}
		}
		this.leftIsPowered = false;
		this.rightIsPowered = false;
		this.gamePaused = false;
		this.engine.clear(this.CLEAR_COLOR, true, true);
		if(this.backgroundMusic) this.backgroundMusic.play();
		setTimeout(() => {  this.canvas.focus(); }, 100);
	}
	
	setCameraPosition(x:number, y:number, z:number) {
		this.camera.position.x = x;
		this.camera.position.y = y;
		this.camera.position.z = z;
		this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
	}
	
	resetCamera() {
		this.setPresetCameraPos(this.isPhoneDevice() ? 9 : this.currentCameraPreset);
	}
	
	// buttonShine() {
	// 	if(this.buttonShineTexture && this.leftButtonShineMaterial) {
	// 		this.nextShine = moment().valueOf() + Math.random() * 3000 + 2000;
	// 		let shineAnim = new BABYLON.Animation("shineAnim", "vOffset", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
	// 		let shineAnim2 = new BABYLON.Animation("shineAnim", "uOffset", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
	// 		let topKeys = [
	// 			{
	// 				frame:0,
	// 				value: 1.433
	// 			},
	// 			{
	// 				frame: 60,
	// 				value: 2.433
	// 			}
	// 		];
			
	// 		let alphaAnim = new BABYLON.Animation("alphaAnim", "alpha", 120, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
	// 		let alphaKeys = [
	// 			{
	// 				frame:0,
	// 				value: 0
	// 			},
	// 			{
	// 				frame: 60,
	// 				value: 1
	// 			},
	// 			{
	// 				frame: 120,
	// 				value: 0
	// 			}
	// 		];
	// 		shineAnim.setKeys(topKeys);
	// 		shineAnim2.setKeys(topKeys);
	// 		alphaAnim.setKeys(alphaKeys);
	// 		this.scene.beginDirectAnimation(this.buttonShineTexture, [shineAnim, shineAnim2], 0, 60, false, 3, () => {
	// 			this.scene.removeAnimation(shineAnim);
	// 			this.scene.removeAnimation(shineAnim2);
	// 		});
	// 		this.scene.beginDirectAnimation(this.leftButtonShineMaterial, [alphaAnim], 0, 120, false, 3, () => {
	// 			this.scene.removeAnimation(alphaAnim);
	// 		});
	// 	}
	// }
	
	animateCamera() {
		if(this.camera) {
			let cameraPreset = window.innerWidth < 768 ? 9 : this.currentCameraPreset;
			let presetSettings = this.getPresetCameraSettings(cameraPreset);
			this.camera.position = new BABYLON.Vector3(-120, 35, 50);
			this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
			let flyByAnimPos = new BABYLON.Animation("flyByAnimPos", "position", 240, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 1);
			let flyByAnimTargetPos = new BABYLON.Animation("flyByAnimTargetPos", "lockedTarget", 240, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 1);
			let flyByAnimPosKeys = [
				{
					frame: 0,
					value: this.camera.position
				},
				{
					frame: 120,
					value: new BABYLON.Vector3(-75, 31.25, 110)
				},
				{
					
					frame: 240,
					value: presetSettings.position.clone()
				}
			];
			let flyByAnimTargetPosKeys = [
				{
					frame: 0,
					value: this.camera.lockedTarget
				},
				{
					
					frame: 240,
					value: presetSettings.target.clone()
				}
			];
			flyByAnimPos.setKeys(flyByAnimPosKeys);
			flyByAnimTargetPos.setKeys(flyByAnimTargetPosKeys);
			if(this.loadingScreen){ 
				this.loadingScreen.style.opacity = "0";
			}
			this.scene.beginDirectAnimation(this.camera, [flyByAnimPos, flyByAnimTargetPos], 0, 240, false, 0.2, () => {
				this.scene.removeAnimation(flyByAnimPos);
				this.setPresetCameraPos(cameraPreset);
				this.animateDropObstacles();
				// animateCamera();
			});
		}
	}
	
	getRandomGoalie() {
		var index = Math.floor(Math.random() * this.goalies.length);
		return this.goalies[index];
	}
	
	isMobileDevice() {
		return this.md.mobile() != null && (this.md.phone() != null || this.md.tablet() != null);
	}
	
	isPhoneDevice() {
		var bounds = this.gameContainer.getBoundingClientRect()
		return this.md.mobile() != null && this.md.phone() != null || window.innerWidth/window.innerHeight < 1;
	}
	
	toggleCameraControls() {
		setTimeout(() => {
			this.cameraControlsActive = this.cameraToggle.classList.contains('active');
			if(this.cameraControlsActive) {
				this.cameraInputs.classList.remove('hidden');
				// console.log('attachControl');
				this.camera.attachControl(this.canvas);
			} else {
				this.camera.detachControl(this.canvas);
				// console.log('detachControl');
				this.cameraInputs.classList.add('hidden');
			}
		}, 300);
	}
	
	getGoaliePlanePosition() {
		return this.goalieObstaclePlanePosition;
	}
	
	optimizeShadowResolution(scene:BABYLON.Scene, optimizer:BABYLON.SceneOptimizer):boolean {
		if(this.engine.getFps() < this.minshadowfps) {
			if(this.currentShadowResolution > this.minShadowResolution) {
				let newResolution = this.currentShadowResolution / 2;
				if(newResolution >= this.minShadowResolution) {
					this.setShadowMapResolution(newResolution);
				}
				return this.engine.getFps() >= this.minshadowfps;
			}
		}
		return true;
	}
	
	rescale(right: boolean) {
		let target = this.canvasScale + (right ? 1 : -1);
		let scaleSteps = this.CANVAS_SCALE_STEPS;
		if (target >= 0 && target < scaleSteps.length) {
			this.canvasScale = target;
			this.updateSceneSize();
		}
	}
	
	// Watch for browser/canvas resize events
	updateSceneSize() {
		// logical / css width
		// TODO check mobile (DPI)
		var bounds = this.gameContainer.getBoundingClientRect()
		let ratio = window.innerWidth/ window.innerHeight;
		let lW = window.innerWidth;
		let lH = this.isPhoneDevice() ? window.innerHeight : window.innerHeight;
		
		// pixel resolution
		let s = this.CANVAS_SCALE_STEPS[this.canvasScale];
		if(this.isPhoneDevice()) s *=  devicePixelRatio;
		var pxW = window.innerWidth * s;
		var pxH = this.isPhoneDevice() ? window.innerHeight * s : window.innerHeight * s;
		// limit to 1080
		let maxWidth = 	!this.isPhoneDevice() ? 1920 : 1920 * window.devicePixelRatio;
		let maxHeight = !this.isPhoneDevice() ? 1080 : 1080 * window.devicePixelRatio;
		if(ratio < 1) {
			maxWidth = 	!this.isPhoneDevice() ? 1080 : 1080 * window.devicePixelRatio;
			maxHeight = !this.isPhoneDevice() ? 1920 : 1920 * window.devicePixelRatio;
		}
		
		if (pxW > maxWidth) {
			pxH = Math.floor(pxH * maxWidth / pxW);
			pxW = maxWidth;
		} else if (pxH > maxHeight) {
			pxW = Math.floor(pxW * maxHeight / pxH);
			pxH = maxHeight;
		}
		
		// then fill the screen using CSS
		if((pxW != this.prevRenderWidth || pxH != this.prevRenderHeight)) {
			this.canvas.style.width = `${lW}px`;
			this.canvas.style.height = `${lH}px`;
			this.scene.render();
			
			this.engine.setSize(pxW, pxH);
			this.engine.setHardwareScalingLevel(1/s);
			if(this.goaliePlane) this.goaliePlane.position = this.getGoaliePlanePosition();
			this.scene.render();
			this.prevRenderWidth = pxW;
			this.prevRenderHeight = pxH;
			// canvas.focus();
			this.updateCamera();
		}
	}
	
	animateDropObstacles() {
		let obstacles = [this.goaliePlane, this.eifel_tower, this.big_ben, this.pisa_tower, this.mill];
		let obstaclesPositions = [this.getGoaliePlanePosition(), this.eifel_tower_position, this.big_ben_position,this.pisa_tower_position, this.mill_position];
		let animations:any[] = [];
		for(let i = 0; i < obstacles.length; i++) {
			let obstacle = obstacles[i];
			let anim = new BABYLON.Animation(obstacle.name + 'Anim', "position", 100, BABYLON.Animation.ANIMATIONTYPE_VECTOR3);
			let keys = [{
				frame:0,
				value: obstacle.position
			}, {
				frame: 100,
				value: obstaclesPositions[i]
			}]
			anim.setKeys(keys);
			animations.push({obstacle: obstacle, anim:anim});
		}
		let runAnim = () => {
			if(animations.length > 0) {
				let item = animations.shift();
				this.scene.beginDirectAnimation(item.obstacle, [item.anim], 0, 100, false, 2, () => {
					runAnim();
					this.scene.removeAnimation(item.anim);
				});
			} else {
				this.onIntroAnimationDone();
			}
		};
		runAnim();
	}

	preventScrollActions(e:any) {
		if(this.cameraControlsActive) return;
		e.preventDefault();
	}
	
	destroyGame() {
		try {
			for(let sound of this.sounds) {
				if(sound.isPlaying) sound.stop();
			}
			
			this.scene.dispose();
		} catch(e) {
			console.log(e);
		}
		this.resetScore();
		$("#game-main").addClass('hidden');
		
		window.onresize = (ev:any) => {};
		this.canvas.onresize = (ev:any) => {};
		this.canvas.ontouchstart = (ev:any) => {};
		this.canvas.ontouchmove = (ev:any) => {};
		this.canvas.ontouchend = (ev:any) => {};
		this.canvas.onpointerup = (ev:any) => {};
		this.canvas.onpointermove = (ev:any) => {};
		this.canvas.onpointerdown = (ev:any) => {};
		this.canvas.onmousedown = (ev:any) => {};
		this.canvas.onmouseup = (ev:any) => {};
		this.canvas.onkeydown = (ev:any) => {};
		this.canvas.onkeyup = (ev:any) => {};
		window.onscroll = (ev:any) => {};
		document.body.onscroll = (ev:any) => {};
		this.canvas.onclick = (ev:any) => {};
	}
}